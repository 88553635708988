<div id="wrapper-app">
  <div id="header">
    <p>Accueil > Mon profil</p>
  </div>

  <form [formGroup]="profilForm" (ngSubmit)="postdata(profilForm)" autocomplete="off">
    <mat-card>
        <mat-card-header>
            <div class="square"><span class="material-icons">person</span></div>
            <h2>Information Personnelles</h2>
        </mat-card-header>

        <mat-card-content>

            <mat-list-item>
                <mat-form-field>
                    <mat-label>Prénom</mat-label>
                    <input matInput formControlName="user_firstname" />
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Nom</mat-label>
                    <input matInput formControlName="user_lastname" />
                </mat-form-field>
            </mat-list-item>

            <mat-list-item>
                <mat-form-field>
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="user_email" placeholder="user_email" />
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Téléphone</mat-label>
                    <input matInput formControlName="user_phone" />
                </mat-form-field>
            </mat-list-item>

            <mat-list-item>
                <mat-form-field>
                    <mat-label>Adresse</mat-label>
                    <input matInput formControlName="user_adress" />
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Code Postale</mat-label>
                    <input matInput formControlName="user_zip_code" />
                </mat-form-field>
            </mat-list-item>

            <mat-list-item>
                <mat-form-field>
                    <mat-label>Ville</mat-label>
                    <input matInput formControlName="user_city" />
                </mat-form-field>
            </mat-list-item>



        </mat-card-content>
        <mat-card-actions>
            <button mat-stroked-button color="primary" type="submit" class="btn btn-success" [disabled]="!profilForm.valid">
                Mettre à jour
            </button>
        </mat-card-actions>
    </mat-card>

    <mat-card *ngIf="vuePMO">
      <mat-card-header>
          <div class="square"><span class="material-icons">info</span></div>
          <h2>Information Producteur/Consommateur</h2>
      </mat-card-header>
        <mat-card-content>
            <mat-list-item>
                <mat-form-field>
                    <mat-label>Boucle:</mat-label>
                    <input matInput formControlName="user_boucle" />
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Usage Point ID</mat-label>
                    <input matInput formControlName="user_usage_point_id" />
                </mat-form-field>
            </mat-list-item>

            <mat-list-item>
                <mat-form-field>
                    <mat-label>Enregistrement Enedis:</mat-label>
                    <input matInput formControlName="user_start_to_enedis" />
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Enregistrement Association:</mat-label>
                    <input matInput formControlName="user_registered_to_asso" />
                </mat-form-field>
            </mat-list-item>

            <mat-list-item>
                <mat-form-field>
                    <mat-label>Référence PRM/PMO:</mat-label>
                    <input matInput formControlName="user_reference_prm_pmo" />
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Référence PRM Producteur:</mat-label>
                    <input matInput formControlName="user_reference_prm_producteur" />
                </mat-form-field>
            </mat-list-item>

            <mat-list-item>
                <mat-form-field>
                    <mat-label>Puissance :</mat-label>
                    <input matInput formControlName="user_puissance" />
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Surface:</mat-label>
                    <input matInput formControlName="user_surface" />
                </mat-form-field>
            </mat-list-item>

            <mat-list-item>
                <mat-form-field>
                    <mat-label>Option Tarifaire:</mat-label>
                    <input matInput formControlName="user_pricing_option" />
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Enregistrement Association:</mat-label>
                    <input matInput formControlName="user_registered_to_asso" />
                </mat-form-field>
            </mat-list-item>

            <mat-list-item>
                <mat-form-field>
                    <mat-label>Chauffage Principal:</mat-label>
                    <input matInput formControlName="user_main_heater" />
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Chauffe eau:</mat-label>
                    <input matInput formControlName="user_hot_water" />
                </mat-form-field>
            </mat-list-item>
        </mat-card-content>
        <mat-list-item>
            <mat-form-field>
                <mat-label>Nombre d'occupants</mat-label>
                <input matInput formControlName="user_number_of_occupants" />
            </mat-form-field>
        </mat-list-item>
        <mat-card-actions>
            <button mat-stroked-button color="primary" type="submit" class="btn btn-success" [disabled]="!profilForm.valid">
                Mettre à jour
            </button>
        </mat-card-actions>
    </mat-card>

    <mat-card>
      <mat-card-header>
          <div class="square"><span class="material-icons">business</span></div>
          <h2>Organisation</h2>
      </mat-card-header>
        <mat-card-content>
            <mat-list-item>
                <mat-form-field>
                    <mat-label>Nom de l'organisation:</mat-label>
                    <input matInput formControlName="user_company_name" />
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Numéro de SIRET:</mat-label>
                    <input matInput formControlName="user_company_siret" />

                </mat-form-field>
            </mat-list-item>

        </mat-card-content>
        <mat-card-actions>
            <button mat-stroked-button color="primary" type="submit" class="btn btn-success" [disabled]="!profilForm.valid">
                Mettre à jour
            </button>
        </mat-card-actions>
    </mat-card>
</form>
<div id="footer">
    <p> <span class="capitalise"> Opération Energie Collective Pérenchies</span></p>
    <p> &copy; <span class="green">Cohérence Energies</span> - Par <span class="green">NetCURD</span>.</p>
</div>
</div>
