import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Routing
import { AppRoutingModule } from './app-routing.module';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';
// Component
import { AppComponent } from './app.component';
import { LoginComponent } from './features/login/login.component';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { HomeComponent } from './features/home/home.component';
import { ConsumptionComponent } from './features/consumption/consumption.component';
import { ProductionComponent } from './features/production/production.component';
import { ProfilComponent } from './features/profil/profil.component';
import { AdministrationComponent } from './features/administration/administration.component';
import { MenuComponent } from './features/menu/menu.component';

// Modale
import { ModalConfirmComponent } from './shared/modal/modalconfirm/modalconfirm.component';
import { ModalprofilComponent } from './shared/modal/modalprofil/modalprofil.component';

// Material design
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';

// ChartJS
//import { ChartModule }                      from 'angular2-chartjs';
import { BaseChartDirective } from 'ng2-charts';

// Date-fns
import { DateFnsModule } from 'ngx-date-fns';
//pipe
import { DateFilterPipe } from './pipe/date-filter/date-filter.pipe';
import { DatePickerMonthDirective } from './directives/datePickerFormat/date-picker-month.directive';
import { DatePickerDayDirective } from './directives/datePickerFormat/date-picker-day.directive';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        DashboardComponent,
        HomeComponent,
        ProfilComponent,
        ProductionComponent,
        ConsumptionComponent,
        AdministrationComponent,
        ModalConfirmComponent,
        MenuComponent,
        ModalprofilComponent,
        DateFilterPipe,
        DatePickerMonthDirective,
        DatePickerDayDirective
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule,
        // Form
        ReactiveFormsModule,
        BrowserAnimationsModule,
        // Routing
        AppRoutingModule,
        // Date-fns
        DateFnsModule,
        // ChartJs
        //ChartModule,
        BaseChartDirective,
        // Material Design
        MatSliderModule,
        MatFormFieldModule,
        MatMenuModule,
        MatTabsModule,
        MatCardModule,
        MatGridListModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatSidenavModule,
        MatButtonToggleModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatSelectModule,
        MatTableModule,
    ],
    providers: [
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        {
            provide: MatDialogRef,
            useValue: {}
        },
        { provide: MAT_DIALOG_DATA, useValue: {} }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
