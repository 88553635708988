<!-- Cadre Gauche -->
<div id="wrapper-app" class="container-fluid">
    <div id="header">
        <p>Accueil > Tableau de bord</p>
    </div>
    <div class="row">
        <div id="identite" class="col-md-6" *ngIf="user ; else monElse ">
            <mat-card>
                <mat-card-header>
                    <div class="square"><span class="material-icons">perm_identity</span></div>
                    <h2>Bonjour {{user.user_firstname }} {{user.user_lastname}}</h2>
                </mat-card-header>
                <mat-card-content>
                    <div>
                        <!-- Bonjour {{user.user_firstname }} {{user.user_lastname}}<br> -->
                        <!-- <h3>Energie collective Pérenchies</h3> -->
                        <p>Bienvenue sur votre espace dédié à l'opération d'autoconsommation<br>"Energie collective Pérenchies".</p>
                        <p>vous pouvez:</p>
                        <ul>
                            <li>Consulter vos informations en tant que participant</li>
                            <li>Constater et visualiser l'énergie consommée au sein de l'opération (année, mois, jour)</li>
                            <li>Identifier les éventuels écarts avec votre fournisseur de complément</li>
                            <li>Engager une démarche vertueuse vers une meilleure utilisation des "heures solaires" pour votre consommation"</li>
                        </ul>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
        <ng-template #monElse>
            <div class="loader"></div>
            chargement en cours...
        </ng-template>
        <!-- Cadre Droit -->
        <div id="info" class="col-md-6" *ngIf="user ; else monElse ">
            <mat-card>
                <mat-card-header>
                    <div class="square"><span class="material-icons">info</span></div>
                    <h2>Informations globales</h2>
                </mat-card-header>
                <ul>
                    <li>N° de PDL/PRM: {{user.user_usage_point_id}}</li>
                    <li>Type: {{roleValue}}</li>
                    <!-- <li>Tarif Bleu:</li> //FIXME a voir avec Adrien-->
                    <li>Chauffage principal: {{user.user_main_heater}}</li>
                    <li>Nombre d'occupants: {{user.user_number_of_occupants}}</li>
                    <li>N° de l'opération: {{user.user_boucle}}</li>
                    <li>Clé de répartition: {{user.user_repartition_keys}} </li>
                    <li>Date d'entrée dans le périmètre: {{user.user_start_to_enedis| date: 'dd MMMM yyyy' :'+0200':'fr'}}</li>
                </ul>
            </mat-card>
        </div>
        <ng-template #monElse>
            <div class="loader"></div>
            chargement en cours...
        </ng-template>
    </div>

    <!-- Petit Tableau periode precedente -->
    <div class="row">
        <div class="col-md-12" *ngIf="graphPie">
            <mat-card>
                <mat-card-header>
                    <div class="square"><span class="material-icons">power_settings_new</span></div>
                    <div>
                        <h2>Energie mise à disposition sur la période</h2>
                        <p> du {{graphPeriodeDebut}} au {{graphPeriodeFin}} suivant clé de répartition :
                            <span *ngIf="autoConsomation; else monElse "> {{autoConsomation}} (kWh)</span>
                        </p>
                        <ng-template #monElse>
                            <div class="loader"></div>
                            chargement en cours...
                        </ng-template>
                    </div>
                </mat-card-header>
                <div id="circle">
                    <div style="display: block">
                        <canvas baseChart [data]="pieChartData" [type]="pieChartType" [options]="pieChartOptions" [plugins]="pieChartPlugins">
                        </canvas>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>

    <!-- Tableau pour les consommations -->
    <div class="row table">

        <div class="col-md-12" *ngIf="tableConsumption">

            <mat-card>

                <mat-card-header>
                    <div class="square"><span class="material-icons">table_chart</span></div>
                    <h2>Tableau des données de consommation des mois précédents</h2>
                    <p>Les périodes sont calculées selon les dates anniversaires,<br>du 26 du mois précédent au 25 du mois présent</p>
                </mat-card-header>
                <div id="table">

                    <table #mytablecons mat-table [dataSource]="dataSourceCons" class="mat-elevation-z8" *ngIf="dataSourceCons.length; else chargementTableau">


                        <ng-container matColumnDef="col1">
                            <th mat-header-cell *matHeaderCellDef>Date de relève</th>
                            <td mat-cell *matCellDef="let element"> {{element.col1}} </td>
                        </ng-container>

                        <ng-container matColumnDef="col2">
                            <th mat-header-cell *matHeaderCellDef> consommation totale<br>du point de <br>livraison<br>(kWh) </th>
                            <td mat-cell *matCellDef="let element"> {{element.col2}} </td>
                        </ng-container>

                        <ng-container matColumnDef="col3">
                            <th mat-header-cell *matHeaderCellDef> Approvisionnement<br> local <br>par l'opération<br>Autoconsommation (kWh)</th>
                            <td mat-cell *matCellDef="let element"> {{element.col3}} </td>
                        </ng-container>

                        <ng-container matColumnDef="col4">
                            <th mat-header-cell *matHeaderCellDef> Taux <br> d'auto<br>consommation </th>
                            <td mat-cell *matCellDef="let element"> {{element.col4}} <span *ngIf="element.col4 !=='cette'"> %</span></td>
                        </ng-container>

                        <ng-container matColumnDef="col5">
                            <th mat-header-cell *matHeaderCellDef> Approvisionnement<br>par le fournisseur<br>de complément<br>(kWh) </th>
                            <td mat-cell *matCellDef="let element"> {{element.col5}} </td>
                        </ng-container>

                        <ng-container matColumnDef="col6">
                            <th mat-header-cell *matHeaderCellDef> Production<br>non alouée<br>non<br>consommée<br>(kWh) </th>
                            <td mat-cell *matCellDef="let element"> {{element.col6}} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumnsCons;sticky:true "></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumnsCons;"></tr>

                    </table>

                    <ng-template #chargementTableau>
                        <div class="loader"></div>
                        Chargement des données.....
                    </ng-template>
                </div>

            </mat-card>

        </div>

    </div>

    <!-- Tableau pour les Productions -->
    <div class="row table">

        <div class="col-md-12" *ngIf="tableProduction">

            <mat-card>

                <mat-card-header>
                    <div class="square"><span class="material-icons">table_chart</span></div>
                    <h2>Tableau des données de production des mois précédents</h2>
                    <p>Les périodes sont calculées selon les dates anniversaires,<br>du 26 du mois précédent au 25 du mois affiché</p>
                </mat-card-header>
                <mat-card-content>

                    <table #mytableprod mat-table [dataSource]="dataSourceProd" class="mat-elevation-z8" *ngIf="dataSourceProd.length; else chargementTableau">

                        <ng-container matColumnDef="col1">
                            <th mat-header-cell *matHeaderCellDef>Date de relève</th>
                            <td mat-cell *matCellDef="let element"> {{element.col1}} </td> <!-- mois -->
                        </ng-container>

                        <ng-container matColumnDef="col2">
                            <th mat-header-cell *matHeaderCellDef>Production<br>injectée<br>dans le<br>périmètre<br>(kWh) </th>
                            <td mat-cell *matCellDef="let element"> {{element.col2}} </td> <!-- production injectée -->
                        </ng-container>

                        <ng-container matColumnDef="col3">
                            <th mat-header-cell *matHeaderCellDef>Production<br>valorisée<br>auprès des<br>consommateurs<br>Autoconsommation (kWh) </th>
                            <td mat-cell *matCellDef="let element"> {{element.col3}} </td> <!-- production valorisée -->
                        </ng-container>

                        <ng-container matColumnDef="col4">
                            <th mat-header-cell *matHeaderCellDef>Production<br>en surplus(kWh)</th>
                            <td mat-cell *matCellDef="let element"> {{element.col4}}</td> <!-- production surplus -->
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumnsProd;sticky:true"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumnsProd;"></tr>

                    </table>

                    <ng-template #chargementTableau>
                        <div class="loader"></div>
                        Chargement des données.....
                    </ng-template>
                </mat-card-content>

            </mat-card>

        </div>

    </div>

</div>

<div id="footer">
    <p> <span class="capitalise"> Opération Energie collective Pérenchies</span></p>
    <p> &copy; <span class="green">Cohérence Energies</span> - Par <span class="green">NetCurd</span>.</p>
</div>