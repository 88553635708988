import { Component, OnInit } from '@angular/core';
// interface
import { User_model } from 'src/app/interfaces/users';
// Services
import { ApiService } from 'src/app/services/api/api.service';
import { ChartjsService } from 'src/app/services/chartjs/chartjs.service';
// form control
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
// formatage de la date
import { format, add, sub, parseISO, startOfYear, endOfYear, startOfMonth, endOfMonth } from 'date-fns';

//Date picker <----------start-------------->
import { MatDatepicker } from '@angular/material/datepicker';
// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
// import _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
// import { default as _rollupMoment, Moment } from 'moment';
import { DateFormatedService } from 'src/app/services/date_formated/date-formated.service';

// const MOMENT = _rollupMoment || _moment;

@Component({
    selector: 'app-consumption',
    templateUrl: './consumption.component.html',
    styleUrls: ['./consumption.component.scss'],
})

export class ConsumptionComponent implements OnInit {

    public barChartType = 'bar';
    public barChartLegend = true;
    public barChartLabels: any;
    public barChartData: any = [];
    // Reglages des Graph
    public graph_default = true;
    public graph_day = false;
    public graph_month = false;
    // material designe settings
    public minDate: any;
    public maxDate: any;

    graphOk: boolean = false;
    //selecteur de plage
    fontStyleControl = new UntypedFormControl();
    user!: User_model;
    barChartLabelsFromSql: any = [];
    ChartData: any = [];
    //Var affichage des picker
    view!: string;
    // Date Picker Year month
    pickerMonth = new UntypedFormControl(new Date());


    public barChartOptions = {
        responsive: true,
        scales: {
            x: {
                type: 'category',
                stacked: true
            },
            y: {
                type: 'linear',
                stacked: true
            }
        },
        plugins: {
            legend: {
                display: true,
            },
            datalabels: {
                anchor: 'end',
                align: 'end',
                display: false
            }
        }
    };

    chosenYearHandler(normalizedYear: Date) {
        const currentValue = this.pickerMonth.value;
        const newDate = new Date(normalizedYear.getFullYear(), currentValue.getMonth());
        this.pickerMonth.setValue(newDate);
    }

    chosenMonthHandler(normalizedMonth: Date, datepicker: MatDatepicker<Date>) {
        const currentValue = this.pickerMonth.value;
        const newDate = new Date(currentValue.getFullYear(), normalizedMonth.getMonth());
        this.pickerMonth.setValue(newDate);

        let dateStart = startOfYear(sub(newDate, { years: 1 }));
        let dateEnd = endOfYear(newDate);

        let range = {
            value: {
                'start': { _d: dateStart },
                'end': { _d: dateEnd }
            }
        };

        this.graphSelect(range);
        datepicker.close();
    }

    // date picker jour
    pickerDay = new UntypedFormGroup(
        {
            start: new UntypedFormControl(),
            end: new UntypedFormControl()
        }
    );

    plageSelect(value: string) {

        this.barChartLabels = [];
        this.barChartData = [];
        const today = new Date();

        switch (value) {
            case "view-day":
                this.view = "view-day";
                this.graph_default = false;
                let dateDayStart = startOfMonth(sub(today, { months: 1 }));
                let dateDayEnd = endOfMonth(sub(today, { months: 1 }));
                this.graphSelect({ value: { 'start': { _d: dateDayStart }, 'end': { _d: dateDayEnd } } });
                break;
            case "view-month":
                this.view = "view-month";
                this.graph_default = false;
                let dateMonthStart = startOfYear(sub(today, { years: 1 }));
                let dateMonthEnd = endOfYear(today);
                this.graphSelect({ value: { 'start': { _d: dateMonthStart }, 'end': { _d: dateMonthEnd } } });
                break;
            case "view-year":
                this.view = "view-year";
                this.graph_default = false;
                let dateYearStart = startOfYear(today);
                let dateYearEnd = endOfYear(today);
                this.graphSelect({ value: { 'start': { _d: dateYearStart }, 'end': { _d: dateYearEnd } } });
                break;
        }
    }

    constructor(
        private _dataService: ApiService,
        private _chartjs: ChartjsService,
        private _dateFormatedService: DateFormatedService
    ) { }

    async ngOnInit() {

        // initialisation des Data pour les graph
        this.barChartLabels = [];
        this.barChartData = [];

        if (this._dataService.isLoggedIn()) {

            // recupère les données utilisateur
            this.user = await this._dataService.getUserData(this._dataService.getToken() + '');

            // si le role de l'utilisateur est 7 c'est un admin
            // if (this.user.user_role_enedis_type == 8) {

            //     // reglage des labels
            //     this.barChartLabels = ['Je', 'suis', 'un', 'Ad', 'mini', 'stra', 'teur'];
            //     // /reglage des données
            //     this.barChartData = [
            //         { data: [10, 20, 30, 40, 50, 60, 70], label: 'Pas de Données' },
            //         { data: [70, 60, 50, 40, 30, 20, 10], label: 'à charger' }
            //     ];
            // } else {
            //     //charge la courbe de Consomation de la date d'inscription a maintenant en année
            //     const BAR_CHART_DATA: any = await this._chartjs.chartjs(this.user.user_start_to_enedis, this._dateFormatedService.dateDuJourFormated(), this.user.user_usage_point_id, 1, false, true, true, false, false, "year");

            //     if (BAR_CHART_DATA) {
            //         this.barChartLabels = BAR_CHART_DATA[0];
            //         this.barChartData = BAR_CHART_DATA[1];

            //         setTimeout(() => {
            //             this.graphOk = true;
            //         }, 1000);
            //     }

            // }

            // material designe settings datepicker
            let userDate = parseISO(this.user.user_start_to_enedis);
            let userYear = userDate.getFullYear();
            let userMonth = userDate.getMonth();
            this.minDate = startOfMonth(userDate);
            // date du mois dernier grace au 0
            this.maxDate = endOfMonth(sub(new Date(), { months: 1 }));

            // Charge le graphique vue année par default
            this.view = "view-year";
            this.graph_default = false;
            let dateStart = startOfYear(new Date());
            let dateEnd = endOfYear(new Date());

            this.graphSelect({ value: { 'start': { _d: dateStart }, 'end': { _d: dateEnd } } });

        }

    }


    async graphSelect(range: { value: { [x: string]: { [x: string]: number | Date; }; }; }) {

        // console.log('range debut:>> ', range["value"]["start"]);
        // console.log('range fin  :>> ', range["value"]["end"]);

        // reglage des labels a Zero
        this.barChartLabels = [];
        this.barChartData = [];

        const DATE_PICKER_START = format(range.value['start']['_d'], 'yyyy-MM-dd'); // => String
        let datePickerEnd = format(range.value['end']['_d'], 'yyyy-MM-dd'); // => String


        switch (this.view) {
            case "view-day":

                const LOAD_CURVES_DATA_DAY: any = await this._chartjs.chartjs(DATE_PICKER_START, datePickerEnd, this.user.user_usage_point_id, 2, false, true, true, false, false, "day");
                const day = [];

                if (LOAD_CURVES_DATA_DAY && LOAD_CURVES_DATA_DAY.length > 0) {
                    this.barChartLabels = LOAD_CURVES_DATA_DAY[0];
                    this.barChartData = LOAD_CURVES_DATA_DAY[1];
                } else {
                    this.barChartLabels = ['', '', '', '', ''];
                    this.barChartData = [
                        { data: [0, 20, 30, 10, 0], label: 'Pas de Données sur cette période' },
                    ];
                }
                break;

            case "view-month":

                // si la date de fin est après la date du jour on mets la date du jour
                let dateEnd = new Date(datePickerEnd);

                // if (dateEnd.getTime() >= Date.now()) {
                //     let maintenant = new Date(Date.now());
                //     let tmp = new Date(maintenant.setMonth(maintenant.getMonth() - 2));
                //     datePickerEnd = tmp.toISOString().split('T')[0];
                // }

                const LOAD_CURVES_DATA_MONTH: any = await this._chartjs.chartjs(DATE_PICKER_START, datePickerEnd, this.user.user_usage_point_id, 2, false, true, true, false, false, "month");

                if (LOAD_CURVES_DATA_MONTH && LOAD_CURVES_DATA_MONTH.length > 0) {

                    this.barChartLabels = LOAD_CURVES_DATA_MONTH[0];
                    this.barChartData = LOAD_CURVES_DATA_MONTH[1];
                } else {
                    this.barChartLabels = ['', '', '', '', ''];
                    this.barChartData = [
                        { data: [0, 20, 30, 10, 0], label: 'Pas de Données sur cette période' },
                    ];
                }
                break;

            case "view-year":

                const LOAD_CURVES_DATA_YEAR: any = await this._chartjs.chartjs(this.user.user_start_to_enedis, datePickerEnd, this.user.user_usage_point_id, 2, false, true, true, false, false, "year");
                //const year = [];
                if (LOAD_CURVES_DATA_YEAR && LOAD_CURVES_DATA_YEAR.length > 0 && LOAD_CURVES_DATA_YEAR[0] != null) {
                    this.barChartLabels = LOAD_CURVES_DATA_YEAR[0];
                    this.barChartData = LOAD_CURVES_DATA_YEAR[1];
                } else {
                    this.barChartLabels = ['', '', '', '', ''];
                    this.barChartData = [
                        { data: [0, 20, 30, 10, 0], label: 'Pas de Données sur cette période' },
                    ];
                }
                break;
        }
        this.graph_default = true;
    }

}