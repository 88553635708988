<div id="menu">
    <div class="brand"><img src="/assets/img/logo_asso.png" /></div>
    <ul>
        <li>
            <mat-icon>dashboard</mat-icon>
            <a routerLink="dashboard">Tableau de bord</a>
        </li>
        <li *ngIf="consumption === true">
            <mat-icon>trending_down</mat-icon>
            <a routerLink="consommation">Ma Consommation</a>
        </li>
        <li *ngIf="production === true">
            <mat-icon>trending_up</mat-icon>
            <a routerLink="production">Ma Production</a>
        </li>
        <li>
            <mat-icon>account_circle</mat-icon>
            <a routerLink="profil">Mon Profil</a>
        </li>
        <li *ngIf="admin === true">
            <mat-icon>supervised_user_circle</mat-icon>
            <a routerLink="administration">Gestion des participants</a>
        </li>
        <li>
            <mat-icon>exit_to_app</mat-icon>
            <a class="centre-text bold-text" mat-icon-button color="warn" (click)="logout()">Déconnexion</a>
        </li>
    </ul>
</div>

<router-outlet></router-outlet>