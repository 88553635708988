import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
// Material designe
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// interface
import { User_model } from 'src/app/interfaces/users';
// service
import { ApiService } from 'src/app/services/api/api.service';
// component
import { ModalConfirmComponent } from '../modalconfirm/modalconfirm.component';
// date-fns
import { format } from 'date-fns';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})

@Component({
    selector: 'app-modalprofil',
    templateUrl: './modalprofil.component.html',
    styleUrls: ['./modalprofil.component.scss']
})
export class ModalprofilComponent implements OnInit {
    vuePMO: boolean = false;
    date = format(new Date(), 'yyyy-MM-dd');

    // LISTE
    //selectedValue!: string;
    //TODO ajouter une route pour récupérer la liste des roles
    roles: any[] = [
        { value: '1', viewValue: 'Consommateur' },
        { value: '2', viewValue: 'Producteur' },
        { value: '3', viewValue: 'Cons & Prod' },
        // { value: '4', viewValue: 'Gest. PMO' },
        // { value: '5', viewValue: 'Gest. Multi PMO' },
        // { value: '6', viewValue: 'Administrateur' },
    ];
    selectedRole = this.data[1].user_role_enedis_type;
    user_usage_point_id = this.data[1].user_usage_point_id;

    // message de confirmation
    result: boolean = false;

    profilForm!: UntypedFormGroup;
    getUser!: UntypedFormGroup;
    user!: User_model;
    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<ModalprofilComponent>,
        private _apiService: ApiService,
        private _router: Router,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }

    ngOnInit() {
        this.profilForm = new UntypedFormGroup({
            user_firstname: new UntypedFormControl(''),
            user_lastname: new UntypedFormControl('', [Validators.required]),
            user_email: new UntypedFormControl('', [Validators.required]),
            user_phone: new UntypedFormControl(''),
            user_adress: new UntypedFormControl(''),
            user_zip_code: new UntypedFormControl(''),
            user_city: new UntypedFormControl(''),
            user_role_enedis_type: new UntypedFormControl('Consommateur', [Validators.required]),
            user_boucle: new UntypedFormControl('', [Validators.required]),
            user_usage_point_id: new UntypedFormControl('', [Validators.required]),
            user_repartition_keys: new UntypedFormControl('0.0', [Validators.required]),
            user_start_to_enedis: new UntypedFormControl('', [Validators.required]),
            user_registered_to_asso: new UntypedFormControl(this.date),
            user_puissance: new UntypedFormControl(''),
            user_surface: new UntypedFormControl(''),
            user_segment: new UntypedFormControl(''),
            user_pricing_option: new UntypedFormControl(''),
            user_main_heater: new UntypedFormControl(''),
            user_hot_water: new UntypedFormControl(''),
            user_number_of_occupants: new UntypedFormControl(''),
            user_company_name: new UntypedFormControl(''),
            user_company_siret: new UntypedFormControl(''),
            user_reference_prm_pmo: new UntypedFormControl(''),
            user_reference_prm_producteur: new UntypedFormControl(''),
        });
        this.getUser = new UntypedFormGroup({
            user_email: new UntypedFormControl('', [Validators.required])
        });

        // cleaning this.data object before pacthvalue form
        if (!this.data[1].user_registered_to_asso) {
            delete this.data[1].user_registered_to_asso;
        }
        this.profilForm.patchValue(this.data[1]);

        //  regele l'affichage du bouton level PMO
        if (this.data[0] == 4) { // Si PMO
            this.vuePMO = false;
            this.roles = [
                { value: '1', viewValue: 'Consommateur' },
                { value: '2', viewValue: 'Producteur' },
                { value: '3', viewValue: 'Cons & Prod' },
                { value: '4', viewValue: 'Gest. PMO' },
            ];
        } else if (this.data[0] == 5) { // Si Multi PMO
            this.vuePMO = false;
            this.roles = [
                { value: '1', viewValue: 'Consommateur' },
                { value: '2', viewValue: 'Producteur' },
                { value: '3', viewValue: 'Cons & Prod' },
                { value: '4', viewValue: 'Gest. PMO' },
                { value: '5', viewValue: 'Gest. Multi PMO' },
            ];

        } else if (this.data[0] == 6) { // Si Admin
            this.vuePMO = true;
            this.roles = [
                { value: '1', viewValue: 'Consommateur' },
                { value: '2', viewValue: 'Producteur' },
                { value: '3', viewValue: 'Cons & Prod' },
                { value: '4', viewValue: 'Gest. PMO' },
                { value: '5', viewValue: 'Gest. Multi PMO' },
                { value: '6', viewValue: 'Administrateur' },
            ];
        }

        // Regle l'affichage des options du profil
        // if (this.selectedRole == 4 || this.selectedRole == 5) {
        //     this.vuePMO = false;
        // }
    }

    async save(event: Event) {
        // this.data.user_role_enedis_type = (event.target as HTMLSelectElement).value;
        // if (this.user_usage_point_id) {
        let response = await new Promise((resolve) => {
            resolve(this._apiService.userUpdate(this.data[1].user_email, this.profilForm.value));
        }).then((data) => {
           return data;
        });

        // FIXME : à revoir la response est null
        // } else {
        //response = await this._apiService.userCreate( this.profilForm.value);
        // }
        this.dialogRef.close(this.profilForm.value);
    }
}