import { Component, Inject, Injectable, OnInit } from '@angular/core';

// Materail Design
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Injectable({
    providedIn: 'root'
})

@Component({
    selector: 'app-modalconfirm',
    templateUrl: './modalconfirm.component.html',
    styleUrls: ['./modalconfirm.component.scss']
})
export class ModalConfirmComponent implements OnInit {

    result: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<ModalConfirmComponent>,
        @Inject(MAT_DIALOG_DATA) public data: boolean,
    ) { }

    ngOnInit() {
        this.result = this.data;
    }

}