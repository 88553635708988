<!-- //TODO ajouter liste des utilisateurs -->
<div id="wrapper-app" class="container-fluid">

    <div id="header">
        <p>Accueil > Gestion des participants</p>
    </div>

    <div class="row">
        <!-- <div class="col-lg-6">

            <mat-card>
                <mat-card-header>
                    <div class="square"><span class="material-icons">info</span></div>
                    <h2>Informations sur la gestion des participants</h2>
                </mat-card-header>
                <mat-card-content>
                    <ul>
                        <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maiores, quam sed veritatis tempora nulla eos possimus molestiae nobis non provident perspiciatis ex quae quod doloremque quasi rerum! Consectetur, enim officiis!<br><br></li>
                        <li>Provident magni minima nostrum velit doloremque a tempore, ea temporibus, quisquam porro, deserunt delectus unde sunt. Pariatur tempore asperiores unde cum consequatur, perferendis, atque placeat laudantium voluptates consectetur necessitatibus blanditiis!<br><br></li>
                        <li>Rem ab esse nam praesentium quisquam, veniam minus perferendis. Provident iste atque sint, nostrum quisquam reiciendis ipsam adipisci doloremque commodi temporibus. Beatae deleniti voluptatum natus tempora vel quo repudiandae error.<br><br></li>
                    </ul>
                </mat-card-content>
            </mat-card>

        </div> -->
        <div class="col-lg-12">

            <mat-card id="user_list">
                <mat-card-header>
                    <div class="square"><span class="material-icons">format_list_bulleted</span></div>
                    <h2>Liste des utilisateurs</h2>
                </mat-card-header>
                <mat-card-content>
                    <table #tableauUsers mat-table [dataSource]="adminUserList" class="mat-elevation-z8">

                        <!--- Note that these columns can be defined in any order.
                          The actual rendered columns are set as a property on the row definition" -->

                        <!-- id Column -->
                        <ng-container matColumnDef="user_usage_point_id">
                            <th mat-header-cell *matHeaderCellDef> Numéro de compteur </th>
                            <td mat-cell *matCellDef="let element"> {{element.user_usage_point_id}} </td>
                        </ng-container>

                        <!-- Name Column -->
                        <ng-container matColumnDef="user_firstname">
                            <th mat-header-cell *matHeaderCellDef> Nom </th>
                            <td mat-cell *matCellDef="let element" > <span *ngIf="element.user_company_name ; else company_template">{{element.user_company_name}}</span>
                            <ng-template #company_template>{{element.user_firstname}} {{element.user_lastname}}</ng-template>
                         </td>
                        </ng-container>

                        <!-- Date Column -->
                        <ng-container matColumnDef="user_start_to_enedis">
                            <th mat-header-cell *matHeaderCellDef> Date d’entrée </th>
                            <td mat-cell *matCellDef="let element"> {{element.user_start_to_enedis}} </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumnsAdminUsersList;sticky: true"></tr>
                        <tr mat-row (click)="user_card(row)" *matRowDef="let row; columns: displayedColumnsAdminUsersList;"></tr>
                    </table>

                </mat-card-content>
            </mat-card>

        </div>
    </div>

    <div class="row">

        <div class="col-lg-12">

            <mat-card>
                <mat-card-header>
                    <div class="square"><span class="material-icons">graphic_eq</span></div>
                    <h2>Informations sur la gestion des participants</h2>
                </mat-card-header>

                <mat-card-content>

                    <div id="selecteur">
                        <mat-button-toggle-group [formControl]="fontStyleControl" aria-label="Font Style">
                            <mat-button-toggle value="view-year" (change)="plageSelect($event.value)">Année</mat-button-toggle>
                            <mat-button-toggle value="view-month" (change)="plageSelect($event.value)">Mois</mat-button-toggle>
                            <mat-button-toggle value="view-day" (change)="plageSelect($event.value)">Jour</mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>

                    <div id="date">
                        <!--  Vue pour jour -->
                        <div class="view" *ngIf="view == 'view-day'">

                            <mat-card-subtitle>Graphique de charge journalière</mat-card-subtitle>
                            <mat-form-field appearance="fill" datePickerDay>
                                <mat-label>Entrez une plage de date</mat-label>
                                <mat-date-range-input [min]="minDate" [max]="maxDate" [formGroup]="pickerDay" [rangePicker]="pickerday">
                                    <input matStartDate formControlName="start" placeholder="Start date" data-date-format="DD-YYYY-MM" >
                                    <input matEndDate formControlName="end" placeholder="End date">
                                </mat-date-range-input>
                                <mat-datepicker-toggle matSuffix [for]="pickerday"></mat-datepicker-toggle>
                                <mat-date-range-picker #pickerday (closed)="graphSelect(pickerDay)"></mat-date-range-picker>
                            </mat-form-field>
                        </div>


                        <!--  Vue par mois -->
                        <div class="view" *ngIf="view == 'view-month'">

                            <mat-card-subtitle>Selectionnez le premier mois pour voir les 12 suivants</mat-card-subtitle>
                            <mat-form-field appearance="fill" datePickerMonth>
                                <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="pickermonth" [formControl]="pickerMonth" >
                                <mat-datepicker-toggle matSuffix [for]="pickermonth"></mat-datepicker-toggle>
                                <mat-datepicker #pickermonth startView="multi-year" (yearSelected)="chosenYearHandler($event)" (monthSelected)="chosenMonthHandler($event, pickermonth)" >
                                </mat-datepicker>
                                <mat-date-range-picker #pickermonth (closed)="graphSelect(pickerMonth)"></mat-date-range-picker>
                            </mat-form-field>
                        </div>

                    </div>

                    <!--  Vue par années -->
                    <div class="view" *ngIf="view == 'view-year'">
                        <p>Vue de toutes les années</p>

                    </div>

                    <div id="graph_default" *ngIf="graph_default; else chargementTableau">
                        <!-- <div > -->
                            <div style="display: block">
                                <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions" [legend]="barChartLegend">
                                </canvas>
                            </div>
                        <!-- </div> -->
                    </div>
                    <ng-template #chargementTableau>
                        <div class="loader"></div>
                        Chargement des données.....
                    </ng-template>

                </mat-card-content>
            </mat-card>

        </div>
    </div>

    <div class="row table">

        <div class="col-md-12">

            <mat-card >

                <mat-card-header>
                    <div class="square"><span class="material-icons">table_chart</span></div>
                    <h2>Tableau des données du {{tableauDesDonnesDebut| date: 'dd MMMM yyyy' :'+0200':'fr'}} au {{tableauDesDonnesFin| date: 'dd MMMM yyyy' :'+0200':'fr'}}</h2>
                </mat-card-header>

                <mat-card-content>

                    <table #tableauRecap mat-table [dataSource]="dataSource"  *ngIf="dataSource.length; else chargement">

                        <ng-container matColumnDef="col1">
                            <th mat-header-cell *matHeaderCellDef>Participants</th>
                            <td mat-cell *matCellDef="let element">{{element.col1}} </td>
                            <td mat-footer-cell *matFooterCellDef>Total </td>
                        </ng-container>

                        <ng-container matColumnDef="col2">
                            <th mat-header-cell *matHeaderCellDef>Consommation totale<br>du point de<br>livraison<br>(kWh) </th>
                            <td mat-cell *matCellDef="let element">{{element.col2}} </td>
                            <td mat-footer-cell *matFooterCellDef>{{elementTotal.col2}} </td>
                        </ng-container>

                        <ng-container matColumnDef="col3">
                            <th mat-header-cell *matHeaderCellDef>Approvisionnement<br>local<br>par l'opération<br>Autoconsommation (kWh)</th>
                            <td mat-cell *matCellDef="let element">{{element.col3}} </td>
                            <td mat-footer-cell *matFooterCellDef>{{elementTotal.col3}} </td>
                        </ng-container>

                        <ng-container matColumnDef="col4">
                            <th mat-header-cell *matHeaderCellDef>Taux <br>d'auto<br>consommation </th>
                            <td mat-cell *matCellDef="let element">{{element.col4}} %</td>
                            <td mat-footer-cell *matFooterCellDef>{{elementTotal.col4}} %</td>
                        </ng-container>

                        <ng-container matColumnDef="col5">
                            <th mat-header-cell *matHeaderCellDef>Approvisionnement<br>par le fournisseur<br>de complément<br>(kWh) </th>
                            <td mat-cell *matCellDef="let element">{{element.col5}} </td>
                            <td mat-footer-cell *matFooterCellDef>{{elementTotal.col5}} </td>
                        </ng-container>

                        <ng-container matColumnDef="col6">
                            <th mat-header-cell *matHeaderCellDef>Production<br>injectée<br>dans le<br>périmètre<br>(kWh)</th>
                            <td mat-cell *matCellDef="let element">{{element.col6}} </td>
                            <td mat-footer-cell *matFooterCellDef>{{elementTotal.col6}} </td>
                        </ng-container>

                        <ng-container matColumnDef="col7">
                            <th mat-header-cell *matHeaderCellDef>Production<br>valorisée<br>auprès des<br>consommateurs<br>(kWh) </th>
                            <td mat-cell *matCellDef="let element">{{element.col7}} </td>
                            <td mat-footer-cell *matFooterCellDef>{{elementTotal.col7}} </td>
                        </ng-container>

                        <ng-container matColumnDef="col8">
                            <th mat-header-cell *matHeaderCellDef>Production<br>en surplus(kWh)</th>
                            <td mat-cell *matCellDef="let element">{{element.col8}} </td>
                            <td mat-footer-cell *matFooterCellDef>{{elementTotal.col8}} </td>
                        </ng-container>

                        <ng-container matColumnDef="col9">
                            <th mat-header-cell *matHeaderCellDef>Part Allouée</th>
                            <td mat-cell *matCellDef="let element">{{element.col9}} %</td>
                            <td mat-footer-cell *matFooterCellDef>{{elementTotal.col9}} %</td>
                        </ng-container>

                        <ng-container matColumnDef="col10">
                            <th mat-header-cell *matHeaderCellDef>Taux<br>de couverture</th>
                            <td mat-cell *matCellDef="let element">{{element.col10}} %</td>
                            <td mat-footer-cell *matFooterCellDef>{{elementTotal.col10}} %</td>
                        </ng-container>

                        <ng-container matColumnDef="col11">
                            <th mat-header-cell *matHeaderCellDef>Clé<br>de répartition</th>
                            <td mat-cell *matCellDef="let element">{{element.col11}}</td>
                            <td mat-footer-cell *matFooterCellDef>{{elementTotal.col11}}</td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>

                    </table>

                    <ng-template #chargement>
                        <div class="loader"></div>
                        Chargement des données <span *ngIf="tablePanelProgression >= 1"></span> {{tablePanelProgression}}%
                    </ng-template>
                </mat-card-content>

            </mat-card>

        </div>

    </div>

    <div id="footer">
        <p><span class="capitalise">Opération Energie collective Pérenchies</span></p>
        <p>&copy; <span class="green">Cohérence Energies</span> - Par <span class="green">NetCurd</span>.</p>
    </div>

</div>
