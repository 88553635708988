<div id="wrapper-app">
    <form [formGroup]="profilForm" autocomplete="off">
        <mat-card>
            <mat-card-header>
                <div class="square"><span class="material-icons">person</span></div>
                <h2>Information Personnelles</h2>
            </mat-card-header>

            <mat-card-content>

                <mat-list-item>
                    <mat-form-field>
                        <mat-label>Prénom</mat-label>
                        <input matInput formControlName="user_firstname" />
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Nom</mat-label>
                        <input matInput formControlName="user_lastname" />
                    </mat-form-field>
                </mat-list-item>

                <mat-list-item>
                    <mat-form-field>
                        <mat-label>Email</mat-label>
                        <input matInput formControlName="user_email" />
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Téléphone</mat-label>
                        <input matInput formControlName="user_phone" />
                    </mat-form-field>
                </mat-list-item>

                <mat-list-item>
                    <mat-form-field>
                        <mat-label>Adresse</mat-label>
                        <input matInput formControlName="user_adress" />
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Code Postale</mat-label>
                        <input matInput formControlName="user_zip_code" />
                    </mat-form-field>
                </mat-list-item>

                <mat-list-item>
                    <mat-form-field>
                        <mat-label>Ville</mat-label>
                        <input matInput formControlName="user_city" />
                    </mat-form-field>
                </mat-list-item>

                <mat-list-item>
                    <mat-form-field>
                        <mat-label>Nombre d'occupants</mat-label>
                        <input matInput formControlName="user_number_of_occupants" />
                    </mat-form-field>

                    <!-- <mat-form-field>
                    <mat-label>Code Postale</mat-label>
                    <input matInput formControlName="user_zip_code" />
                </mat-form-field>-->
                </mat-list-item>

            </mat-card-content>
        </mat-card>

        <mat-card>
            <mat-card-header>
                <div class="square"><span class="material-icons">info</span></div>
                <h2   *ngIf="vuePMO; else pasPMO">Information Producteur/Consommateur</h2>
                <ng-template #pasPMO><h2>Information PMO/PRM</h2></ng-template>
            </mat-card-header>
            <mat-card-content>
                <mat-list-item>
                    <mat-form-field>
                        <mat-label>Boucle:</mat-label>
                        <input matInput formControlName="user_boucle" />
                    </mat-form-field>

                    <mat-form-field *ngIf="vuePMO">
                        <mat-label>Usage Point ID</mat-label>
                        <input matInput formControlName="user_usage_point_id" />
                    </mat-form-field>
                </mat-list-item>

                <mat-list-item *ngIf="vuePMO">
                    <mat-form-field>
                        <mat-label>Clé de répartition:</mat-label>
                        <input matInput formControlName="user_repartition_keys" />
                    </mat-form-field>
                </mat-list-item>

                <mat-list-item *ngIf="vuePMO">
                    <mat-form-field>
                        <mat-label>Entrée Enedis:</mat-label>
                        <input matInput formControlName="user_start_to_enedis" />
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Entrée Association:</mat-label>
                        <input matInput formControlName="user_registered_to_asso" />
                    </mat-form-field>
                </mat-list-item>

                <mat-list-item>
                    <mat-form-field>
                        <mat-label>Référence PRM/PMO:</mat-label>
                        <input matInput formControlName="user_reference_prm_pmo" />
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Référence PRM Producteur:</mat-label>
                        <input matInput formControlName="user_reference_prm_producteur" />
                    </mat-form-field>
                </mat-list-item>

                <mat-list-item *ngIf="vuePMO">
                    <mat-form-field>
                        <mat-label>Puissance :</mat-label>
                        <input matInput formControlName="user_puissance" />
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Surface:</mat-label>
                        <input matInput formControlName="user_surface" />
                    </mat-form-field>
                </mat-list-item>

                <mat-list-item>
                    <mat-form-field *ngIf="vuePMO">
                        <mat-label>Option Tarifaire:</mat-label>
                        <input matInput formControlName="user_pricing_option" />
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Status:</mat-label>
                        <mat-select matNativeControl [(value)]="selectedRole" formControlName="user_role_enedis_type">
                            <mat-option [value]="role.value" *ngFor="let role of roles">{{role.viewValue}}</mat-option>
                        </mat-select>
                        <!-- <input matInput formControlName="user_role_enedis_type" /> -->
                    </mat-form-field>
                </mat-list-item>
                <mat-list-item *ngIf="vuePMO">
                    <mat-form-field>
                        <mat-label>Chauffage Principal:</mat-label>
                        <input matInput formControlName="user_main_heater" />
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Chauffe eau:</mat-label>
                        <input matInput formControlName="user_hot_water" />
                    </mat-form-field>
                </mat-list-item>
            </mat-card-content>
        </mat-card>

        <mat-card>
            <mat-card-header>
                <div class="square"><span class="material-icons">business</span></div>
                <h2>Organisation</h2>
            </mat-card-header>
            <mat-card-content>
                <mat-list-item>
                    <mat-form-field>
                        <mat-label>Nom de l'entreprise:</mat-label>
                        <input matInput formControlName="user_company_name" />
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Numéro de SIRET:</mat-label>
                        <input matInput formControlName="user_company_siret" />
                    </mat-form-field>
                </mat-list-item>

            </mat-card-content>

            <!-- <button mat-stroked-button color="warn"(click)="save()" color="warn">Supprimer le compte</button> -->

        </mat-card>


        <!-- <mat-card>
            <mat-card-actions>
            <button
                mat-stroked-button
                color="primary"
                type="submit"
                class="btn btn-success"
            >
                Créer
            </button>
            </mat-card-actions>
        </mat-card> -->
    </form>

    <!-- <pre> {{user|json}} </pre> -->

    <mat-dialog-actions>

        <button class="supergreen" mat-button mat-dialog-close>Annuler</button>

        <button class="mat-raised-button supergreen" (click)="save($event)" [disabled]="!profilForm.valid">Mettre à jour
        </button>

    </mat-dialog-actions>
</div>