import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';

// formatage de la date
import { format } from 'date-fns';
import { User_model } from 'src/app/interfaces/users';
import { DateFormatedService } from '../date_formated/date-formated.service';

@Injectable({
    providedIn: 'root'
})
// Material Table

/**
 * Construit un tableau avec tout les statistiques user et met en forme directement pour le mat-table
 *
 * @params date_start
 * @params date_end
 * @params user_usage_point_id
 * @params view
 *
 * @return {
 *          "col0": compteur,
 *          "col1": user_usage_point_id,
 *          "col2": consommationTotalduPointdeLivraison,
 *          "col3": approvisionnementLocalParLOperation
 *          "col4": tauxAutoconsommation,
 *          "col5": approvisionnementParLeFournisseurDeComplement,
 *          "col6": productionInjecteDansLePerimetre,
 *          "col7": productionValoriseAupresDesconsommateurs,
 *          "col8": productionEnSurplus,
 *          "col9": tauxAutoProduction,
 *          "col10": tauxDeCouverture,
 *          "col11": repartitionKey
 *
 *          }
 */
export class TablePanelService {

    constructor(
        private _dataService: ApiService,
        private _dateFormatedService: DateFormatedService) { }

    /**
     *
     * Appel toutes les fonctions pour construire les données du tableau administrateur puis crée le tableau d'objects
     *
     * @params date_start: string date de debut
     * @params date_end: string date de fin
     * @params user_usage_point_id: string usage point id de l'utilisateur
     * @params view: string day month year
     *
     * @return [data]: array of data
     */
    async adminTablePanel(date_start: string, date_end: string, user: User_model, view: string) {

        // console.log('date_start," - ", date_end," - ", user: User_model, view," - " :>> ', date_start," - ", date_end," - ", user ," - ", view," - ");
        //col 2
        let consommationTotalduPointdeLivraison: any = await new Promise((resolve) => {
            resolve(this.consommationTotalduPointdeLivraison(date_start, date_end, user.user_usage_point_id, view));
        }).then((data) => {
            return data;
        });
        //col 3
        let approvisionnementLocalParLOperation: any = await new Promise((resolve) => {
            resolve(this.approvisionnementLocalParLOperation(date_start, date_end, user, view));
        }).then((data) => {
            return data;
        });
        //col4
        let tauxAutoconsommation: any = await new Promise((resolve) => {
            resolve(this.tauxAutoconsommation(date_start, date_end, user, view));
        }).then((data) => {
            return data;
        });

        //col5
        let approvisionnementParLeFournisseurDeComplement: any = await new Promise((resolve) => {
            resolve(this.approvisionnementParLeFournisseurDeComplement(date_start, date_end, user, view));
        }).then((data) => {
            return data;
        });

        //col6
        let productionInjecteDansLePerimetre: any = await new Promise((resolve) => {
            resolve(this.productionInjecteDansLePerimetre(date_start, date_end, user, view));
        }).then((data) => {
            return data;
        });

        //col7
        let productionValoriseAupresDesconsommateurs: any = await new Promise((resolve) => {
            resolve(this.productionValoriseAupresDesconsommateurs(date_start, date_end, user, view));
        }).then((data) => {
            return data;
        });

        //col8

        let productionEnSurplus: any = await new Promise((resolve) => {
            resolve(this.productionEnSurplus(date_start, date_end, user, view)); //TODO : ajouter le calcul de la production en surplus si consommateu)r
        }).then((data) => {
            return data;
        });
        //col9
        let tauxAutoConsommationPartAlloue: any = await new Promise((resolve) => {
            resolve(this.tauxAutoConsommationPartAlloue(date_start, date_end, user, view)); // Part Alloué)e
        }).then((data) => {
            return data;
        });

        //col10
        let tauxDeCouverture: any = await new Promise((resolve) => {
            resolve(this.tauxDeCouverture(date_start, date_end, user, view));
        }).then((data) => {
            return data;
        });


        let data: any = {
            col0: format(new Date(date_start), "yyyyMM"),
            col1: user.user_lastname + " " + user.user_usage_point_id,
            col2: consommationTotalduPointdeLivraison,
            col3: approvisionnementLocalParLOperation,
            col4: tauxAutoconsommation,
            col5: approvisionnementParLeFournisseurDeComplement,
            col6: productionInjecteDansLePerimetre,
            col7: productionValoriseAupresDesconsommateurs,
            col8: productionEnSurplus,
            col9: tauxAutoConsommationPartAlloue,
            col10: tauxDeCouverture,
            col11: ""
        };
        console.log('table-panel data 134 :>> ', data);
        return [data];
    }

    /**
        *
        * Appel toutes les fonctions pour construire les données du tableau utilisateur puis crée le tableau d'objects
        *
        * @params date_start: string
        * @params date_end: string
        * @params user_usage_point_id: string
        * @params view: string
        *
        * @return [data]: array of data
        */
    async userTablePanelCons(date_start: string, user: User_model, view: string) {

        const DATE_START_FORMATED = this._dateFormatedService.lastMonthDateStart({ date: date_start });
        const DATE_END_FORMATED = this._dateFormatedService.lastMonthDateEnd({ date: date_start });

        // console.log('Date Conso :>> ', date_start,"- Start >>",DATE_START_FORMATED,"- End >>",DATE_END_FORMATED);
        //col 2
        let consommationTotalduPointdeLivraison: any = await this.consommationTotalduPointdeLivraison(DATE_START_FORMATED, DATE_END_FORMATED, user, view);
        //col 3
        let approvisionnementLocalParLOperation: any = await this.approvisionnementLocalParLOperation(DATE_START_FORMATED, DATE_END_FORMATED, user, view);

        // converti en 0 si NaN
        (isNaN(approvisionnementLocalParLOperation)) ? approvisionnementLocalParLOperation = 0 : approvisionnementLocalParLOperation;
        //col4
        let tauxAutoconsommation: any = await this.tauxAutoconsommation(DATE_START_FORMATED, DATE_END_FORMATED, user, view);
        //col5
        let approvisionnementParLeFournisseurDeComplement: any = await this.approvisionnementParLeFournisseurDeComplement(DATE_START_FORMATED, DATE_END_FORMATED, user, view);
        //col8
        let productionEnSurplus: any = await this.productionEnSurplus(DATE_START_FORMATED, DATE_END_FORMATED, user, view);

        let date = format(this._dateFormatedService.lastMonthDateStart({ date: date_start, returnObject: true }), "dd.MM.yy") + " - " + format(this._dateFormatedService.lastMonthDateEnd({ date: date_start, returnObject: true }), "dd.MM.yy");
        let data = {
            col0: format(new Date(date_start), "yyyyMM"),
            col1: format(this._dateFormatedService.lastMonthDateEnd({ date: date_start, returnObject: true }), "yyyy-MM-dd"),
            // col1: date,
            col2: consommationTotalduPointdeLivraison.toFixed(0),
            col3: (approvisionnementLocalParLOperation).toString(),
            col4: tauxAutoconsommation,
            col5: (approvisionnementParLeFournisseurDeComplement).toFixed(0),
            col6: productionEnSurplus
        };

        // console.log('data :>> ', data);
        return data;

    }
    async userTablePanelProd(date_start: string, user: User_model, view: string) {

        const DATE_START_FORMATED = this._dateFormatedService.lastMonthDateStart({ date: date_start });
        const DATE_END_FORMATED = this._dateFormatedService.lastMonthDateEnd({ date: date_start });

        // console.log('Date Prod :>> ', date_start,"- Start >>",DATE_START_FORMATED,"- End >>",DATE_END_FORMATED);

        // //col 2 OK
        const productionInjecteDansLePerimetre: any = await this.productionInjecteDansLePerimetre(DATE_START_FORMATED, DATE_END_FORMATED, user, view);
        // console.log('productionInjecteDansLePerimetre :>> ', productionInjecteDansLePerimetre);
        // //col 3 AutoConsomation totale
        const productionValoriseAupresDesconsommateurs: any = await new Promise((resolve) => {
            resolve(this.autoconsommationDeLaBoucle(DATE_START_FORMATED, DATE_END_FORMATED, view));
        }).then((data) => {
            // console.log('productionValoriseAupresDesconsommateurs :>> ', data);
            return data;
        });
        //(isNaN(productionValoriseAupresDesconsommateurs)) ? productionValoriseAupresDesconsommateurs = 0 : productionValoriseAupresDesconsommateurs;

        // //col4
        let productionEnSurplus: any = await this.productionEnSurplus(DATE_START_FORMATED, DATE_END_FORMATED, user, view);//TODO : ajouter le calcul de la production en surplus si consommateur

        // //col5
        let tauxAutoconsommation: any = await this.tauxAutoconsommation(DATE_START_FORMATED, DATE_END_FORMATED, user, view);

        let date = format(this._dateFormatedService.lastMonthDateStart({ date: date_start, returnObject: true }), "dd.MM.yy") + " - " + format(this._dateFormatedService.lastMonthDateEnd({ date: date_start, returnObject: true }), "dd.MM.yy");
        let data = {
            col0: format(new Date(date_start), "yyyyMM"),
            col1: format(this._dateFormatedService.lastMonthDateEnd({ date: date_start, returnObject: true }), "yyyy-MM-dd"),
            // col1: date,
            col2: productionInjecteDansLePerimetre,
            col3: productionValoriseAupresDesconsommateurs.toFixed(0),
            col4: productionEnSurplus,
            //col5: tauxAutoconsommation
        };

        return data;
    }

    // ------------------------ Logique ------------------------

    //col 2
    /**
        * consommation Total du Point de Livraison
        *
        * @params date_start: string
        * @params date_end: string
        * @params user_usage_point_id: string
        * @params view: string
        * @returns consommationTotalduPointdeLivraison: int
        */
    async consommationTotalduPointdeLivraison(date_start: string, date_end: string, user: any, view: string) {


        let dayData: any = [];

        // si user = "x" alors user.user_usage_point_id = "x"
        user = this.userCkeck(user);

        const LOAD_CURVES_DATA = await new Promise((resolve) => {

            resolve(this._dataService.getCurveData(date_start, date_end, user, 'cons', view));
        }).then((data) => {
            // console.log("fn resolve consommationTotalduPointdeLivraison", data);
            return data;
        });

        LOAD_CURVES_DATA ? dayData = LOAD_CURVES_DATA : "";

        // day data contient tous les mois entre les deux dates
        if (dayData[0]['data']) {
            const consommationTotalduPointdeLivraison = dayData[0]['data'].reduce((p: string, c: string) => parseFloat(p) + parseFloat(c), 0);

            return parseInt(consommationTotalduPointdeLivraison, 10); // suppression de parsInt pour avoir le meme resultat de le camembert
        } else {
            return 0;
        }
    }

    //col3
    /**
        * consommation Total du Point de Livraison
        *
        * @params date_start: string
        * @params date_end: string
        * @params user_usage_point_id: string
        * @params view: string
        * @returns consommationTotalduPointdeLivraison: int
        */
    async approvisionnementLocalParLOperation(date_start: string, date_end: string, user: User_model, view: string) {

        // Appel les méthodes pour récupérer les données
        let DAY_DATA_CONS: any | number = await new Promise((resolve) => {
            resolve(this.consommationTotalduPointdeLivraison(date_start, date_end, user, view));
        }).then((data) => {
            return data;
        });
        let DAY_DATA_COMPLEMENT: any | number = await new Promise((resolve) => {
            resolve(this.approvisionnementParLeFournisseurDeComplement(date_start, date_end, user, view));
        }).then((data) => {
            return data;
        });

        let result = true;

        // Vérifier que les valeurs ne sont pas null
        DAY_DATA_CONS ? DAY_DATA_CONS = DAY_DATA_CONS : result = false;
        DAY_DATA_COMPLEMENT ? DAY_DATA_COMPLEMENT = DAY_DATA_COMPLEMENT : result = false;

        const approvisionnementLocalParLOperation = DAY_DATA_CONS - DAY_DATA_COMPLEMENT;

        return approvisionnementLocalParLOperation;

    }

    //col4
    /**
        * auto consommation Total du Point de Livraison
        *
        * @params date_start: string
        * @params date_end: string
        * @params user_usage_point_id: string
        * @params view: string
        * @returns consommationTotalduPointdeLivraison: int
        */
    async tauxAutoconsommation(date_start: string, date_end: string, user: User_model, view: string) {

        // console.log("tauxAutoconsommation", date_start, date_end, user_usage_point_id, view);
        let data: any;
        let data_1: any;
        let data_2: any;

        //  récupère les consommations totales
        data_1 = await new Promise((resolve) => {
            resolve(this.consommationTotalduPointdeLivraison(date_start, date_end, user, view));
        }).then((data) => {
            return data;
        });

        //  récupère l'autoconsommation totale
        const LOAD_CURVES_DATA = await new Promise((resolve) => {
            resolve(this._dataService.getCurveData(date_start, date_end, user.user_usage_point_id, 'autocons', view));
        }).then((data) => {
            return data;
        });

        LOAD_CURVES_DATA ? data_2 = LOAD_CURVES_DATA : "";
        data_2 = data_2[0]['data'].reduce((p: string, c: string) => parseFloat(p) + parseFloat(c), 0);

        if (data_1 && data_2) {
            // pourcentage = grand nbr * 100 / petit nbr
            data = ((data_2 * 100) / data_1);
            // coupe a deux chiffre après la virgule
            data = data.toFixed(1);
            // si le pourcentage est > 100, % = 100
            if (data > 100) {
                data = 100;
            }

        } else {
            data = 0;
        }

        return data;
    }

    //col 5
    /**
        * consommation Total du Point de Livraison
        *
        * @params date_start: string
        * @params date_end: string
        * @params user_usage_point_id: string
        * @params view: string
        * @returns consommationTotalduPointdeLivraison: int
        */
    async approvisionnementParLeFournisseurDeComplement(date_start: string, date_end: string, user: any, view: string) {

        let dayData: any = [];
        let result = true;

        // si user = "x" alors user.user_usage_point_id = "x"
        user = this.userCkeck(user);

        const LOAD_CURVES_DATA = await new Promise((resolve) => {
            resolve(this._dataService.getCurveData(date_start, date_end, user, 'complement', view));
        }).then((data) => {
            // console.log('data :>> ', data);
            return data;
        });

        LOAD_CURVES_DATA ? dayData = LOAD_CURVES_DATA : result = false;
        // day data contient tous les mois entre les deux dates

        const approvisionnementParLeFournisseurDeComplement = dayData[0]['data'].reduce((p: string, c: string) => parseFloat(p) + parseFloat(c), 0);
        return parseInt(approvisionnementParLeFournisseurDeComplement, 10); // suppression de parsInt pour avoir le meme resultat de le camembert

    }

    //Col6
    /**
        * Production Injecté Dans Le Perimetre
        *
        * @params date_start: string
        * @params date_end: string
        * @params user_usage_point_id: string
        * @params view: string
        * @returns approvisionnementParLeFournisseurDeComplement: int
        */
    async productionInjecteDansLePerimetre(date_start: string, date_end: string, user: any, view: string) {

        let dayData: any = [];

        // si user = "x" alors user.user_usage_point_id = "x"
        user = this.userCkeck(user);

        const LOAD_CURVES_DATA = await new Promise((resolve) => {
            resolve(this._dataService.getCurveData(date_start, date_end, user, 'prod', "month"));
        }).then((data) => {
            return data;
        });

        LOAD_CURVES_DATA ? dayData = LOAD_CURVES_DATA : dayData = 0;
        // console.log("dayData[0]['data'] :>> ", dayData[0]['data'][0]);

        // day data contient tous les mois entre les deux dates
        // si la valeur de dayData[0]['data'] est superieur a 0 alors on fait la somme
        if (dayData[0]['data'] > 0) {
            const productionInjecteDansLePerimetre = dayData[0]['data'].reduce((p: string, c: string) => parseFloat(p) + parseFloat(c), 0);
            return parseInt(productionInjecteDansLePerimetre, 10);
        } else {
            return 0;
        }

        // if (dayData[0]['data'].value != "0") {
        // console.log("object 0 ");
        //     const approvisionnementParLeFournisseurDeComplement = dayData[0]['data'].reduce((p: string, c: string) => parseFloat(p) + parseFloat(c), 0);
        //     return approvisionnementParLeFournisseurDeComplement.toFixed(0);
        // }

    }

    //col7
    /**
    * Production Valorise Aupres Des consommateurs
    *
    * @params date_start: string
    * @params date_end: string
    * @params user_usage_point_id: string
    * @params view: string
    * @returns productionValoriseAupresDesconsommateurs: int
    */
    async productionValoriseAupresDesconsommateurs(date_start: string, date_end: string, user: User_model, view: string) {

        // let approvisionnementLocalParLOperation: any = await this.approvisionnementLocalParLOperation(date_start, date_end, user, view, user.user_repartition_keys);

        // let productionInjecteDansLePerimetre: any | number = await new Promise((resolve) => {
        //     resolve(this.productionInjecteDansLePerimetre(date_start, date_end, user, view));
        // }).then((data) => {
        //     return data;
        // });

        let autoConsommationDeLaBoucle: any | number = await new Promise((resolve) => {
            resolve(this.consommationTotalduPointdeLivraison(date_start, date_end, "x", view));
        }).then((data) => {
            return data;
        });

        // let approvisionnementParLeFournisseurDeComplement: any | number = await new Promise((resolve) => {
        //     resolve(this.approvisionnementParLeFournisseurDeComplement(date_start, date_end, "x", view));
        // }).then((data) => {
        //     return data;
        // });

        // console.log("Prod injecté ", productionInjecteDansLePerimetre, "Cons Total", consommationTotalduPointdeLivraison, "Comple", approvisionnementParLeFournisseurDeComplement, "Cons - Compl ", (consommationTotalduPointdeLivraison - approvisionnementParLeFournisseurDeComplement), "% =>", ((consommationTotalduPointdeLivraison - approvisionnementParLeFournisseurDeComplement) * 100 / productionInjecteDansLePerimetre));
        // let productionValoriseAupresDesconsommateurs = ((consommationTotalduPointdeLivraison - approvisionnementParLeFournisseurDeComplement) * 100 / productionInjecteDansLePerimetre);


        if ("productionInjecteDansLePerimetre") {
            //     let productionValoriseAupresDesconsommateurs = (productionInjecteDansLePerimetre - (consommationTotalduPointdeLivraison - approvisionnementParLeFournisseurDeComplement));
            //     return productionValoriseAupresDesconsommateurs;
            return 1;
        } else {
            return 0;
        }


        // console.log("production valorise aupres des consommateurs", productionValoriseAupresDesconsommateurs);

    }

    //col8
    /**
    * Production En Surplus
    *
    * @params date_start: string
    * @params date_end: string
    * @params user_usage_point_id: string
    * @params view: string
    * @returns productionEnSurplus: int
    */
    async productionEnSurplus(date_start: string, date_end: string, user: any, view: string) {
        let dayData: any = [];
        let result = true;

        // si user = "x" alors user.user_usage_point_id = "x"
        user = this.userCkeck(user);

        const LOAD_CURVES_DATA = await new Promise((resolve) => {
            resolve(this._dataService.getCurveData(date_start, date_end, user, 'surplus', view));
        }).then((data) => {
            return data;
        });
        LOAD_CURVES_DATA ? dayData = LOAD_CURVES_DATA : result = false;

        // day data contient tous les mois entre les deux dates

        const productionEnSurplus = dayData[0]['data'].reduce((p: string, c: string) => parseFloat(p) + parseFloat(c), 0);
        return productionEnSurplus.toFixed(0);
    }

    //col9
    /**
    * Taux Auto Production
    *
    * @params date_start: string
    * @params date_end: string
    * @params user_usage_point_id: string
    * @params view: string
    * @returns tauxAutoProduction: int
    */
    async tauxAutoProduction(date_start: string, date_end: string, user: User_model, view: string) {
        return "tauxAutoProduction";
    }

    /**
    * Taux De Couverture
    *
    * @params date_start: string
    * @params date_end: string
    * @params user_usage_point_id: string
    * @params view: string
    * @returns tauxDeCouverture: int
    */
    async tauxDeCouverture(date_start: string, date_end: string, user: User_model, view: string) {
        let data: any;
        let data_1: any;
        let data_2: any;

        //  récupère les consommations totales
        data_1 = await new Promise((resolve) => {
            resolve(this.productionInjecteDansLePerimetre(date_start, date_end, user, view));
        }).then((data) => {
            return data;
        });;

        //  récupère l'autoconsommation totale
        const LOAD_CURVES_DATA = await new Promise((resolve) => {
            resolve(this._dataService.getCurveData(date_start, date_end, "x", 'autocons', view));
        }).then((data) => {
            return data;
        });

        LOAD_CURVES_DATA ? data_2 = LOAD_CURVES_DATA : "";
        data_2 = data_2[0]['data'].reduce((p: string, c: string) => parseFloat(p) + parseFloat(c), 0);

        if (data_1 && data_2) {
            // pourcentage = grand nbr * 100 / petit nbr
            data = ((data_1 * 100) / data_2);
            // coupe a deux chiffre après la virgule
            data = data.toFixed(1);
            // si le pourcentage est > 100, % = 100
            if (data > 100) {
                data = 100;
            }

        }

        return data;
    }

    /**
    * Taux Auto Consommation Part Alloue
    *
    * @params date_start: string
    * @params date_end: string
    * @params user_usage_point_id: string
    * @params view: string
    * @returns tauxAutoConsommationPartAlloue: int
    */
    async tauxAutoConsommationPartAlloue(date_start: string, date_end: string, user: any, view: string) {

        let data: any;
        let data_1: any;
        let data_2: any;

        //  récupère les consommations totales
        data_1 = await new Promise((resolve) => {
            resolve(this.approvisionnementLocalParLOperation(date_start, date_end, user, view));
        }).then((data) => {
            return data;
        });
        data_2 = await new Promise((resolve) => {
            resolve(this.productionInjecteDansLePerimetre(date_start, date_end, "x", view));
        }).then((data) => {
            return data;
        });


        // Calcul du pourcentage
        if (data_1 && data_2) {
            data = ((data_1 * 100) / data_2).toFixed(1); // multi par 100 pour avoir le pourcentage
        } else {
            data = 0;
        }

        return data;
    }

    /**
    * Production Alloue Non Consommée
    *
    * @params date_start: string
    * @params date_end: string
    * @params user_usage_point_id: string
    * @params view: string
    * @returns productionAlloueNonConsommee: int
    */
    async productionAlloueNonConsommee() {
        return "productionAlloueNonConsommee";
    }

    async autoconsommationDeLaBoucle(date_start: string, date_end: string, view: string) {
        let dayData: any = [];
        //  récupère l'autoconsommation totale
        const LOAD_CURVES_DATA = await new Promise((resolve) => {
            resolve(this._dataService.getCurveData(date_start, date_end, "x", 'autocons', view));
        }).then((data) => {
            return data;
        });

        LOAD_CURVES_DATA ? dayData = LOAD_CURVES_DATA : dayData = [];

        if (dayData[0]['data'] > 0) {
            const autoconsommationDeLaBoucle = dayData[0]['data'].reduce((p: string, c: string) => parseFloat(p) + parseFloat(c), 0);
            return parseInt(autoconsommationDeLaBoucle, 10);
        } else {
            return 0;
        }
    }

    /**
     * @Author: John Ben
     * @Date: 2022-12-16 13:47:47
     * @Desc: check if user = X or not
     */
    userCkeck(user: any) {

        if (user.user_usage_point_id != undefined && user.user_usage_point_id != "x") {
            user = user.user_usage_point_id;
        } else {
            user = "x";
        }

        return user;
    }
}