import { Injectable, Output, EventEmitter } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { User_model } from 'src/app/interfaces/users';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class ApiService {

    redirectUrl!: string;

    // Utilisation directe de baseUrl depuis environment
    baseUrl = environment.baseUrl + "/src/routes/angular";

    constructor(private _httpClient: HttpClient) { }

    public userlogin(email: string, password: string) {
        const REQUEST = this._httpClient.post<User_model[]>(this.baseUrl + '/login.php', { email, password }, { withCredentials: true }) // Ajout de withCredentials
            .pipe(map(Users => {
                if (!Users[0]) {
                    return;
                }
                this.setToken(Users[0].user_email);
                return Users[0] || false;
            }));

        return firstValueFrom(REQUEST);
    }

    // token
    setToken(token: string) {
        sessionStorage.setItem('token', token);
    }

    getToken() {
        return sessionStorage.getItem('token');
    }

    deleteToken() {
        sessionStorage.removeItem('token');
    }

    isLoggedIn() {
        const usertoken = this.getToken();
        return !!usertoken;  // Simplification de la vérification
    }


    public getUserData(email: string) {
        const REQUEST = this._httpClient.post<User_model[]>(this.baseUrl + '/user.php', { email }, { withCredentials: true }) // Ajout de withCredentials
            .pipe(map(Users => {
                return Users[0];
            }));
        return firstValueFrom(REQUEST);
    }

    public getUserList() {
        const REQUEST = this._httpClient.post<any[]>(this.baseUrl + '/user_list.php', {}, { withCredentials: true }) // Ajout de withCredentials
            .pipe(map(Users => {
                return Object.values(Users);
            }));
        return firstValueFrom(REQUEST);
    }

    async userUpdate(email: string, data: any) {
        const postdata = { ...data, email };
        const REQUEST = this._httpClient.post<any[]>(this.baseUrl + '/user_update.php', { postdata }, { withCredentials: true }); // Ajout de withCredentials
        return await firstValueFrom(REQUEST);
    }


    /**
     * @param date_start:string
     * @param date_end:string
     * @param user_id:number usage_point_id 01234567890123
     * @param type:string cons,prod,autocons,surplus
     * @param view:string day, month, year
     */
    async getCurveData(date_start: string, date_end: string, user_id: string, type: string, view: string, calendar: boolean = false) {
        const req = this._httpClient.post<any>(this.baseUrl + '/definitive_load_curves.php',
            { date_start, date_end, user_id, type, view },
            { withCredentials: true }); // Ajout de withCredentials
        return await firstValueFrom(req);
    }
    /**
     * @param date_start:string
     * @param date_end:string
     * @param user_id:string usage point id 01234567890123
     * @param type:string cons,prod,autocons,surplus
     * @param view:string day, month, year
     */
    async getActiveEnergyData(date_start: string, date_end: string, user_id: string, type: string, view: string) {
        const req = this._httpClient.post<any>(this.baseUrl + '/definitive_active_energy.php',
            { date_start, date_end, user_id, type, view },
            { withCredentials: true }); // Ajout de withCredentials
        return await firstValueFrom(req);
    }
}