<div id="wrapper-app" class="container-fluid">
    <div id="header">
        <p>Accueil > Ma production</p>
    </div>


    <div class="row">

        <div class="col-lg-12">
            <mat-card>
                <mat-card-header>
                    <div class="square"><span class="material-icons">graphic_eq</span></div>
                </mat-card-header>
                <section>
                    <div id="selecteur">
                        <mat-button-toggle-group [formControl]="fontStyleControl" aria-label="Font Style">
                            <mat-button-toggle value="view-year" (change)="plageSelect($event.value)">Année</mat-button-toggle>
                            <mat-button-toggle value="view-month" (change)="plageSelect($event.value)">Mois</mat-button-toggle>
                            <mat-button-toggle value="view-day" (change)="plageSelect($event.value)">Jour</mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>




                    <div id="date">
                        <!--  Vue pour jour -->
                        <div class="view" *ngIf="view == 'view-day'">
                            <mat-card>
                                <mat-card-subtitle>Graphique de charge journalière</mat-card-subtitle>
                                <mat-form-field appearance="fill" datePickerDay>
                                    <mat-label>Entrez une plage de date</mat-label>
                                    <mat-date-range-input [min]="minDate" [max]="maxDate" [formGroup]="pickerDay" [rangePicker]="picker">
                                        <input matStartDate formControlName="start" placeholder="Début">
                                        <input matEndDate formControlName="end" placeholder="Fin">
                                    </mat-date-range-input>
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-date-range-picker #picker (closed)="graphSelect(pickerDay)"></mat-date-range-picker>

                                </mat-form-field>
                            </mat-card>
                        </div>

                        <!--  Vue par mois -->
                        <div class="view" *ngIf="view == 'view-month'">
                            <mat-card>
                                <mat-card-subtitle>Selectionnez le premier mois pour voir les 12 suivants<br>selon la date anniversaire</mat-card-subtitle>
                                <mat-form-field appearance="fill" datePickerMonth>
                                    <input [min]="minDate" [max]="maxDate" matInput [matDatepicker]="dp" placeholder="Mois et Années" [formControl]="pickerMonth">
                                    <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                                    <mat-datepicker #dp startView="multi-year" (yearSelected)="chosenYearHandler($event)" (monthSelected)="chosenMonthHandler($event, dp)" panelClass="example-month-picker">
                                    </mat-datepicker>
                                    <mat-date-range-picker #picker (closed)="graphSelect(pickerMonth)"></mat-date-range-picker>
                                </mat-form-field>
                            </mat-card>
                        </div>

                        <!--  Vue par mois -->
                        <div class="view" *ngIf="view == 'view-year'">
                            <p>Vue de toutes les années</p>

                        </div>
                    </div>

                </section>

                <div class="row">
                    <div class="col-lg-12">
                        <mat-card>

                            <div id="graph_default" *ngIf="graph_default ; else waitChargement">
                                <div>
                                    <div style="display: block">
                                        <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions" [legend]="barChartLegend">
                                        </canvas>
                                    </div>
                                </div>
                            </div>
                            <ng-template #waitChargement>
                                <div class="loader"></div>
                                chargement en cours...
                            </ng-template>


                        </mat-card>
                    </div>
                </div>

            </mat-card>

        </div>

    </div>

    <div id="footer">
        <p> <span class="capitalise"> Opération Energie collective Pérenchies</span></p>
        <p> &copy; <span class="green">Cohérence Energies</span> - Par <span class="green">NetCurd</span>.</p>
    </div>
</div>