export const environment = {
    production: true,
    /** Adresse du dossier racine du serveur
     * root  <= adresse à utiliser sans le slash de fin
     * ┣ backend
     * ┣ frontend
     * ┗ index.php
     */
    // baseUrl: "https://autoconso.blsetup.com",
    // baseUrl: "https://autoconso.blsetup.local:4200"
    // baseUrl: '/src/routes/angular'  // Chemin relatif en production
    baseUrl: ''

};
