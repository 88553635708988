import { Directive } from '@angular/core';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

export const MY_FORMATS_MONTH = {
    parse: {
        dateInput: 'MM/YYYY',
    },
    display: {
        dateInput: 'MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Directive({
  selector: '[datePickerMonth]',
    providers: [
        // {
        //     provide:DateAdapter,
        //     useClass: MomentDateAdapter,
        //     deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        // },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS_MONTH },
        { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    ],
})
export class DatePickerMonthDirective {

  constructor() { }

}
