import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
// Interface
import { User_model } from 'src/app/interfaces/users';
import { AdminPanel } from 'src/app/interfaces/admin_panel';
// Services
import { ApiService } from 'src/app/services/api/api.service';
import { ChartjsService } from 'src/app/services/chartjs/chartjs.service';
import { TablePanelService } from 'src/app/services/table_panel/table-panel.service';
// Modale
import { ModalprofilComponent } from 'src/app/shared/modal/modalprofil/modalprofil.component';
// date-fns
import { format } from 'date-fns';
// Materail Design
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';

//Date picker <----------start-------------->
import { MatDatepicker } from '@angular/material/datepicker';
// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';
import { DateFormatedService } from 'src/app/services/date_formated/date-formated.service';

const MOMENT = _rollupMoment || _moment;

@Component({
    selector: 'app-administration',
    templateUrl: './administration.component.html',
    styleUrls: ['./administration.component.scss'],
})

export class AdministrationComponent implements OnInit {

    // Reglages des Graph
    public graph_default: boolean = true;
    public graph_day: boolean = false;
    public graph_month: boolean = false;
    public tablePanelProgression: number = 0;
    //public barChartType = 'bar';
    public barChartLegend = true;
    public barChartLabels: any;
    public barChartData: any = [];

    // material designe settings
    public minDate!: Date;
    public maxDate!: Date;

    // affichage des date dans le HTML
    public tableauDesDonnesDebut!: string;
    public tableauDesDonnesFin!: string;
    // pour l'actualisation du tableau
    /**
     * {static:false} => pour lui dire que c'est un elememt de la page construit plus tard
     * @ViewChild(MatTable,{static:false}) table!: MatTable<any>;
     */
    @ViewChild(MatTable, { static: false }) tableauRecap!: MatTable<any>;
    @ViewChild(MatTable, { static: false }) tableauUsers!: MatTable<any>;

    displayedColumnsAdminUsersList: string[] = ['user_usage_point_id', 'user_firstname', 'user_start_to_enedis'];
    displayedColumns: string[] = ['col1', 'col2', 'col3', 'col4', 'col5', 'col6', 'col8', 'col9', 'col11'];
    //  Tableau pour le total bas de tableaux
    elementTotal: any = { col1: "Total", col2: "0", col3: "0", col4: "0", col5: "0", col6: "0", col7: "0", col8: "0", col9: "0", col10: "0", col11: "0" };

    // initialize la date du jour
    DATE_DU_JOUR = new Date(Date());


    // elementData: AdminPanel[] = [];

    // Variable pour l'affichage du tableau
    tableau: any = [];

    // dataSource!: Array<AdminPanel>;
    dataSource: AdminPanel[] = [];

    // Initialize la liste des utilisateurs pour le panneau de droite
    admin_user_list: any[] = [];
    adminUserList: any[] = [];

    //selecteur de plage
    fontStyleControl = new UntypedFormControl();

    // Initialize User pour afficher les infoamrtions utilisateur
    user!: User_model;

    //Var affichage des picker
    view!: string;

    // Date Picker Year month
    pickerMonth = new UntypedFormControl(MOMENT());

    //------------------------------------ ChartJS
    barChartLabelsFromSql: any = [];
    ChartData: any = [];
    public barChartOptions = {
        scaleShowVerticalLines: false,
        responsive: true,
        scales: {
            xAxes: {
                stacked: true
            },
            yAxes: {
                stacked: true,
                // min: 10 // quantité de depart
            }
        },
        plugins: {
            legend: {
                // labels:{ // couleur du texte
                //     color: "green"
                // }
            },
            datalabels: {
                anchor: 'end',
                align: 'end',
                display: false
            },
            // tooltip: {
            // enable: false,
            // backgroundColor: "red",
            // bodyColor: "green",
            // footerColor:"blue"
            // }
        }
    };

    // date picker jour
    pickerDay = new UntypedFormGroup(
        {
            start: new UntypedFormControl(),
            end: new UntypedFormControl()
        }
    );

    chosenYearHandler(normalizedYear: Moment) {
        const CTRL_VALUE = this.pickerMonth.value;
        CTRL_VALUE.year(normalizedYear.year());
        this.pickerMonth.setValue(CTRL_VALUE);
    }

    chosenMonthHandler(normlizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
        this.pickerMonth.value.month(normlizedMonth.month());
        this.pickerMonth.setValue(this.pickerMonth.value);
        let dateStart = this._dateFormatedService.lastMonthDateStart({ date: this.pickerMonth.value, returnObject: true });
        //  Ajoute un an a la date
        let dateEnd = this._dateFormatedService.lastMonthDateEnd({ date: this._dateFormatedService.yearNextTwelveMonth(this.pickerMonth.value), returnObject: true });

        //construction de l'objet Range avec les dates start et End
        let range = {
            value:
            {
                'start': { _d: dateStart },
                'end': { _d: dateEnd }
            }
        };

        this.graphSelect(range);
        datepicker.close();

    }


    constructor(
        private _dialog: MatDialog,
        private _apiService: ApiService,
        private _chartjs: ChartjsService,
        private _tablePanel: TablePanelService,
        private _dateFormatedService: DateFormatedService,
    ) { }

    async ngOnInit() {

        // initialisation des Data pour les graph
        this.barChartLabels = [];
        this.barChartData = [];

        if (this._apiService.isLoggedIn()) {

            // recupere les données utilisateur
            this.user = await this._apiService.getUserData(this._apiService.getToken() + '');

            // recupère la liste des utilisateurs
            this.admin_user_list = await this.get_user_list();

            // on récupère que les cons et prod pour les afficher dans le tableau
            if (this.user.user_role_enedis_type < 6) {
                this.adminUserList = this.admin_user_list.filter((user: any) => user.user_role_enedis_type <= 3);
            } else {
                this.adminUserList = this.admin_user_list.filter((user: any) => user.user_role_enedis_type <= 6);
            }

            // initialisation des Data pour le tableau information des participants
            // const CHART_DATA: any = await this._chartjs.chartjs(this.user.user_start_to_enedis, this._dateFormatedService.dateDuJourFormated(), "x", 1, false, true, true, false, false, "year");

            // if (CHART_DATA) {
            //     this.barChartLabels = CHART_DATA[0];
            //     this.barChartData = CHART_DATA[1];
            // }

            // material design settings datepicker
            let userDate = new Date(this.user.user_start_to_enedis);
            let userYear = userDate.getFullYear();
            let userMonth = userDate.getMonth();
            this.minDate = new Date(userYear, userMonth, 1);
            // date du mois dernier grace au 0
            this.maxDate = new Date(this._dateFormatedService.toDay().getFullYear(), this._dateFormatedService.toDay().getMonth() - 1, 0);

            // Charge le graphique vue année par default
            this.view = "view-year";
            this.graph_default = false;
            let dateStart = new Date(format(this._dateFormatedService.toDay(), "yyyy-01-01"));
            let dateEnd = new Date(format(this._dateFormatedService.toDay(), "yyyy-12-31"));

            this.graphSelect({ value: { 'start': { _d: dateStart }, 'end': { _d: dateEnd } } });

            // Creation du tableau AdminPanel en bas de page
            await this.tablePanel();
        }

    }

    async user_card(user: any) {

        // recupere les données utilisateur
        const dialogProfil = this._dialog.open(ModalprofilComponent, { data: [this.user.user_role_enedis_type, user] });
        dialogProfil.afterClosed().subscribe(async () => {
            let tmp: any = await new Promise((resolve) => {
                resolve(this.get_user_list());
            }).then((data) => {
                return data;
            });

            this.admin_user_list = tmp;
            try {
                this.tableauUsers.renderRows();
            } catch (error) {
                console.debug(error);
            }

            //  rafriachissement de la page
            this.ngOnInit();

        });
    }

    /**
     * @Author: John Ben
     * @Date: 2022-10-11 17:29:25
     * @Desc: retourne la liste de chaque utilisateur sous forme objet
     *   [
     *    {
     *      "user_id"
     *      "user_role_enedis_type"
     *    },
     *  ]
     */
    async get_user_list() {

        let result: any = [];
        if (this._apiService.isLoggedIn()) {
            const USER_LIST = await this._apiService.getUserList();

            // Ne retourne pas les administrateurs qui ont un compte 000000000000
            if (Number(this.user.user_role_enedis_type) <= 5) {
                result = USER_LIST.filter(user => user.user_role_enedis_type <= 5); //.map({(user)} => user.user_usage_point_id);
            } else {
                // si admin retoune tout
                result = USER_LIST.filter(user => user.user_role_enedis_type <= 6);
            }

        }

        return result;
    }

    /**
     * @Author : John Ben
     * @Date : 2022-10-11 17:27:22
     * @Desc : Plage select est la selection Année mois jour
     * @param value qui est la valeur envoyé par le bouton
     */
    plageSelect(value: string) {

        this.barChartLabels = [];
        this.barChartData = [];

        switch (value) {
            // FIXME: 2022-10-11 17:27:22 passer sur le service Date comme consomation
            case "view-day":
                this.view = "view-day";
                this.graph_default = false;
                let dateDayStart = new Date(this.DATE_DU_JOUR.setMonth(this.DATE_DU_JOUR.getMonth() - 2));
                let dateDayEnd = this.DATE_DU_JOUR;
                dateDayEnd.setMonth(dateDayEnd.getMonth() + 1);

                this.graphSelect({ value: { 'start': { _d: dateDayStart }, 'end': { _d: dateDayEnd } } });
                break;

            case "view-month":
                this.view = "view-month";
                this.graph_default = false;
                let dateMonthStart = new Date(format(this.DATE_DU_JOUR, "yyyy-01-01"));
                dateMonthStart = new Date(dateMonthStart.setMonth(dateMonthStart.getMonth() - 1));
                this.graphSelect({ value: { 'start': { _d: dateMonthStart }, 'end': { _d: this.DATE_DU_JOUR } } });
                break;

            case "view-year":
                this.view = "view-year";
                this.graph_default = false;
                let dateStart = new Date(format(this.DATE_DU_JOUR, "yyyy-01-01"));

                this.graphSelect({ value: { 'start': { _d: dateStart }, 'end': { _d: this.DATE_DU_JOUR } } });
                break;
        }
    }

    async graphSelect(range: { value: { [x: string]: { [x: string]: number | Date; }; }; }) {

        this.barChartLabels = [];
        this.barChartData = [];

        //FIXME: range value start is null
        const DATEPICKERSTART = format(range.value['start']['_d'], 'yyyy-MM-dd');

        let datePickerEnd = format(range.value['end']['_d'], 'yyyy-MM-dd');

        switch (this.view) {
            case "view-day":

                const LOAD_CURVES_DATA_DAY: any = await this._chartjs.chartjs(DATEPICKERSTART, datePickerEnd, "x", 2, true, true, true, false, true, "day");

                if (LOAD_CURVES_DATA_DAY && LOAD_CURVES_DATA_DAY.length > 0) {

                    this.barChartLabels = LOAD_CURVES_DATA_DAY[0];
                    this.barChartData = LOAD_CURVES_DATA_DAY[1];
                } else {
                    this.barChartLabels = ['', '', '', '', ''];
                    this.barChartData = [
                        { data: [0, 20, 30, 10, 0], label: 'Pas de Données sur cette période' },
                    ];
                }
                break;

            case "view-month":

                // si la date de fin est après la date du jour on mets la date du jour
                let dateEnd = new Date(datePickerEnd);
                if (dateEnd.getTime() >= Date.now()) {
                    let maintenant = new Date(Date.now());
                    let tmp = new Date(maintenant.setMonth(maintenant.getMonth() - 2));
                    datePickerEnd = tmp.toISOString().split('T')[0];
                }

                const LOAD_CURVES_DATA_MONTH: any = await this._chartjs.chartjs(DATEPICKERSTART, datePickerEnd, "x", 2, true, true, true, false, true, "month");

                if (LOAD_CURVES_DATA_MONTH && LOAD_CURVES_DATA_MONTH.length > 0) {

                    this.barChartLabels = LOAD_CURVES_DATA_MONTH[0];
                    this.barChartData = LOAD_CURVES_DATA_MONTH[1];
                } else {
                    this.barChartLabels = ['', '', '', '', ''];
                    this.barChartData = [
                        { data: [0, 20, 30, 10, 0], label: 'Pas de Données sur cette période' },
                    ];
                }
                break;

            case "view-year":

                const LOAD_CURVES_DATA_YEAR: any = await this._chartjs.chartjs(this.user.user_start_to_enedis, datePickerEnd, "x", 2, true, true, true, false, true, "year");
                //const year = [];
                if (LOAD_CURVES_DATA_YEAR && LOAD_CURVES_DATA_YEAR.length > 0 && LOAD_CURVES_DATA_YEAR[0] != null) {
                    this.barChartLabels = LOAD_CURVES_DATA_YEAR[0];
                    this.barChartData = LOAD_CURVES_DATA_YEAR[1];
                } else {
                    this.barChartLabels = ['', '', '', '', ''];
                    this.barChartData = [
                        { data: [0, 20, 30, 10, 0], label: 'Pas de Données sur cette période' },
                    ];
                }
                break;
        }
        this.graph_default = true;
    }

    /**
     * @Author: John Ben
     * @Date: 2022-10-11 17:40:07
     * @Desc: Tableau des données du mois précédent selon la date anniversaire
     */
    async tablePanel() {

        let nbrConsommateur: number = 0;
        let tablePanel: any[] = [];
        //initialise Date du mois précédent
        this.tableauDesDonnesDebut = this._dateFormatedService.lastMonthDateStart({ gap:true });
        this.tableauDesDonnesFin = this._dateFormatedService.lastMonthDateEnd({ gap:true });

        // pour chaque element
        await new Promise(async (resolve) => {
            for (let index = 0; index < this.admin_user_list.length; index++) {
                // Element contient l'User complet
                const element = this.admin_user_list[index];

                // if (parseInt(element.user_usage_point_id) >= 1000 && element.user_usage_point_id !== '' && element.user_role_enedis_type <= 3) {
                if (parseInt(element.user_usage_point_id, 10) >= 1000 && element.user_role_enedis_type <= 4) {

                    //Si nbrConsommateur on ajout 1 pour faire la moyenne
                    if (element.user_role_enedis_type == 1) {
                        nbrConsommateur++;
                    }

                    // on appel admin Table
                    await this._tablePanel.adminTablePanel(this.tableauDesDonnesDebut, this.tableauDesDonnesFin, element, 'month').then((e) => {

                        // convert element.user_repartition_keys to float
                        let repartition_keys = parseFloat(element.user_repartition_keys).toFixed(0);
                        // on ajoute la cle de repartition en fin de tableau
                        e[0].col11 = repartition_keys;

                        // on rajoute la ligne au tableau
                        tablePanel.push(e[0]);

                        // calcul le pourcentage d'avancement
                        this.tablePanelProgression = Math.round(100 - (100 / index));
                    });
                }

            }
            resolve(1);
        });

        await new Promise((resolve) => {

            // pour chaque object dans le tableau calcule des totaux
            for (let index = 0; index < tablePanel.length; index++) {

                for (const key in tablePanel[index]) {
                    // console.log('tablePanel[index] :>> ', tablePanel[index]);
                    if (key != "col1") {
                        const element = tablePanel[index][key];
                        // ajout le nouveau nombre et l'enregistre
                        this.elementTotal[key] = parseFloat(this.elementTotal[key]) + parseFloat(element);
                    }
                }
            }

            // Moyennes
            // Col4 taux d'autoConsommation
            this.elementTotal["col4"] = (this.elementTotal["col4"] / nbrConsommateur).toFixed(1);
            // Col9 Part Alloué
            this.elementTotal["col9"] = (this.elementTotal["col9"] / nbrConsommateur).toFixed(1);

            // affiche le tableau
            this.dataSource = tablePanel;
            resolve(1);
        });

    }

}
