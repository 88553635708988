
<!-- <pre> {{result | json }}</pre> -->

<div *ngIf="result; else elseTemplate">

    <h1>Confirmation</h1>
    <h1 mat-dialog-title>Enregistrement effectué</h1>
    <div mat-dialog-content>Les données ont été enregistrées.</div>
    <div mat-dialog-actions>
        <button mat-button mat-dialog-close>Fermer</button>
    </div>
</div>
<ng-template #elseTemplate>
    <h1>Attention !!</h1>
    <h1 mat-dialog-title>Enregistrement non effectué</h1>
    <div mat-dialog-content>Les données n'ont pas été sauvegardé.</div>
    <div mat-dialog-actions>
        <button mat-button mat-dialog-close>Fermer</button>
    </div>
</ng-template>

