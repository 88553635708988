import { Component, AfterViewInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
// interface
import { User_model } from 'src/app/interfaces/users';
// services
import { ApiService } from 'src/app/services/api/api.service';
// modale
import { ModalConfirmComponent } from 'src/app/shared/modal/modalconfirm/modalconfirm.component';
// router
// Material Designe
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-profil',
    templateUrl: './profil.component.html',
    styleUrls: ['./profil.component.scss']
})

export class ProfilComponent implements AfterViewInit {

    vuePMO: boolean  = false;
    res = {};
    profilForm: UntypedFormGroup;
    user!: User_model;
    constructor(
        private _dataService: ApiService,
        public dialog: MatDialog
    ) {
        this.profilForm = new UntypedFormGroup({
            user_firstname: new UntypedFormControl('', [Validators.required]),
            user_lastname: new UntypedFormControl('', [Validators.required]),
            user_email: new UntypedFormControl({ value: '', disabled: true }, [Validators.required]),
            user_phone: new UntypedFormControl(''),
            user_adress: new UntypedFormControl('', [Validators.required]),
            user_zip_code: new UntypedFormControl('', [Validators.required]),
            user_city: new UntypedFormControl('', [Validators.required]),
            user_boucle: new UntypedFormControl({ value: '', disabled: true }, [Validators.required]),
            user_usage_point_id: new UntypedFormControl({ value: '', disabled: true }, [Validators.required]),
            user_start_to_enedis: new UntypedFormControl({ value: '', disabled: true }, [Validators.required]),
            user_registered_to_asso: new UntypedFormControl({ value: '', disabled: true }, [Validators.required]),
            user_role_enedis_type: new UntypedFormControl({ value: '', disabled: false }, [Validators.required]),
            user_puissance: new UntypedFormControl(''),
            user_surface: new UntypedFormControl(''),
            user_segment: new UntypedFormControl({ value: '', disabled: true }, [Validators.required]),
            user_pricing_option: new UntypedFormControl(''),
            user_main_heater: new UntypedFormControl(''),
            user_hot_water: new UntypedFormControl(''),
            user_number_of_occupants: new UntypedFormControl(''),
            user_company_name: new UntypedFormControl(''),
            user_company_siret: new UntypedFormControl(''),
            user_reference_prm_pmo: new UntypedFormControl({ value: '', disabled: true }, [Validators.required]),
            user_reference_prm_producteur: new UntypedFormControl({ value: '', disabled: true }, [Validators.required]),
        });
    }

    async ngAfterViewInit() {
        if (this._dataService.isLoggedIn()) {
            const USER = await this._dataService.getUserData(this._dataService.getToken() + '');
            this.user = USER;
            this.profilForm.patchValue(this.user);

            if (this.user.user_role_enedis_type < 4) {
                this.vuePMO = true;
            }


        }
    }

    async postdata(profilForm: { value: ''; }) {

        // initialisation des données par default
        let postdataDefault = {
            user_boucle: this.user.user_boucle,
            user_email: this.user.user_email,
            user_reference_prm_pmo: this.user.user_reference_prm_pmo,
            user_reference_prm_producteur: this.user.user_reference_prm_producteur,
            user_registered_to_asso: this.user.user_registered_to_asso,
            user_repartition_keys: this.user.user_repartition_keys,
            user_segment: this.user.user_segment,
            user_start_to_enedis: this.user.user_start_to_enedis,
            user_usage_point_id: this.user.user_usage_point_id
        };

        //on creer un nouvelle object avec toute les données
        // assignation des données car ... ne focntionne pas sur .value
        const postDate = Object.assign({}, profilForm.value, postdataDefault);

        const REPONSE = await this._dataService.userUpdate(this.user.user_email, postDate);

        if (REPONSE) {
            this.dialog.open(ModalConfirmComponent,
                {
                    data: { result: true },
                });

        } else {
            this.dialog.open(ModalConfirmComponent,
                {
                    data: { result: false },
                });
        }
    }
}