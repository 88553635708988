import { Injectable } from '@angular/core';
// router
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
// services
import { ApiService } from 'src/app/services/api/api.service';

@Injectable({
    providedIn: 'root'
})

export class AuthguardGuard  {
    constructor(private dataService: ApiService, private router: Router) { }
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {

        // dernière adresse
        const ROUTE_URL: string = state.url;

        // Si connecte retourne true
        if (this.dataService.isLoggedIn()) {
            return true;
        }

        // this.dataService.redirectUrl = ROUTE_URL;
        // this.router.navigate([ '/login' ], { queryParams: { returnUrl: ROUTE_URL } });
        this.router.navigate(['/login']);
        return false;
    }
}