import { AfterViewInit, Component, OnInit } from '@angular/core';
// interface
import { User_model } from 'src/app/interfaces/users';
// service
import { ApiService } from 'src/app/services/api/api.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent {

    user!: User_model;
    admin: boolean = false;
    consumption: boolean = false;
    production: boolean = false;

    constructor(
        private _dataService: ApiService
    ) { }

    async ngAfterViewInit() {
        if (this._dataService.isLoggedIn()) {

            const USER = await this._dataService.getUserData(this._dataService.getToken() + '');
            this.user = USER;

            // this.user.user_role_enedis_type est une string
            switch (String(this.user.user_role_enedis_type)) {
                case '1': // consommateur
                    this.consumption = true;
                    break;
                case '2': // Producteur
                    this.production = true;
                    break;
                case '3': // Cons & Prod
                    this.consumption = true;
                    this.production = true;
                    break;
                case '4': // Gest. PMO
                    this.admin = true;
                    break;
                case '5': // Gest. Multi PMO
                    this.admin = true;
                    break;
                case '6': // Adminstrateur
                    this.consumption = true;
                    this.production = true;
                    this.admin = true;
                    break;
                default:
                    break;
            }

        }

    }


    logout() {
        this._dataService.deleteToken();
        window.location.reload();
    }
}