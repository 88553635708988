import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// component
import { LoginComponent } from './features/login/login.component';
import { HomeComponent } from './features/home/home.component';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { AuthguardGuard } from './guards/authguard/authguard.guard';
import { MenuComponent } from './features/menu/menu.component';
import { ProfilComponent } from './features/profil/profil.component';
import { ConsumptionComponent } from './features/consumption/consumption.component';
import { ProductionComponent } from './features/production/production.component';
import { AdministrationComponent } from './features/administration/administration.component';

const routes: Routes = [

    {
        path: 'accueil',
        component: HomeComponent,
        canActivate: [AuthguardGuard],
        children: [
            { path: '', component: DashboardComponent },
            { path: 'profil', component: ProfilComponent },
            { path: 'consommation', component: ConsumptionComponent },
            { path: 'production', component: ProductionComponent },
            { path: 'administration', component: AdministrationComponent },

        ]
    },
    { path: 'login', component: LoginComponent },
    { path: '', redirectTo: '/accueil', pathMatch: 'full' },
    { path: '**', redirectTo: '/accueil', pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forRoot(
        routes,
        { onSameUrlNavigation: 'reload' }
    )],
    exports: [RouterModule]
})

export class AppRoutingModule { }