import { Component, AfterViewInit, ViewChild, OnInit } from '@angular/core';
import { registerLocaleData } from "@angular/common";
// services
import { ApiService } from 'src/app/services/api/api.service';
// interface
import { User_model } from 'src/app/interfaces/users';
// Services
import { ChartjsService } from 'src/app/services/chartjs/chartjs.service';
import { TablePanelService } from 'src/app/services/table_panel/table-panel.service';
// formatage de la date
import { format, add, parseISO } from 'date-fns'; // Importing date-fns methods
import { fr } from 'date-fns/locale';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr, 'fr');
// ChartJs PIE
import { BaseChartDirective } from 'ng2-charts';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import DatalabelsPlugin from 'chartjs-plugin-datalabels';

// Material Design
import { MatTable } from '@angular/material/table';
import { AdminPanel } from 'src/app/interfaces/admin_panel';
import { DateFormatedService } from 'src/app/services/date_formated/date-formated.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {

    // pour l'actualisation du tableauCons
    @ViewChild(MatTable, { static: false }) mytablecons!: MatTable<Element>;
    @ViewChild(MatTable, { static: false }) mytableprod!: MatTable<Element>;

    // Text du graph PIE
    graphPeriodeDebut: null | string = null;
    graphPeriodeFin: null | string = null;

    // Reglage du nombre de colones
    displayedColumnsCons: string[] = ['col1', 'col2', 'col3', 'col4', 'col5'];
    displayedColumnsProd: string[] = ['col1', 'col2', 'col3', 'col4'];
    elementData: AdminPanel[] = [];

    // dataSourceCons
    dataSourceCons: AdminPanel[] = [];
    dataSourceProd: AdminPanel[] = [];

    // Variable pour l'affichage du tableauCons
    tableauCons: any = [];
    tableauConstotal: any = { col1: "0", col2: "0", col3: "0", col4: "0", col5: "0" };
    tableauProd: any = [];
    tableauProdTotal: any = { col1: "0", col2: "0", col3: "0", col4: "0" };

    // Initialize la liste des utilisateurs pour le panneau de droite
    admin_user_list!: any[];
    user!: User_model;

    // Nom usersType
    roleValue!: string;

    public autoConsomation!: number | string;

    // affcihe le graphique si non PMO
    public graphPmo: boolean = false;
    public tableConsumption: boolean = false;
    public tableProduction: boolean = false;
    public graphPie: boolean = false;

    //------------------------------------ ChartJS
    barChartLabelsFromSql: any = [];
    ChartData: any = [];
    public barChartOptions: any = {

        scaleShowVerticalLines: true,
        responsive: true,
        scales: {
            xAxes: {
                stacked: true
            },
            yAxes: {
                stacked: true,
                // min: 10
            }
        },
        plugins: {
            legend: {
                display: true,
            },
            datalabels: {
                anchor: 'end',
                align: 'end',
                display: false,
            },
            tooltips: {
                callbacks: {
                    label: function (tooltipItem: { yLabel: any; }) {
                        return tooltipItem.yLabel;
                    }
                }
            }
        }
    };
    //public barChartType = 'bar';
    public barChartLegend: boolean = true;
    public barChartLabels: any = [];
    public barChartData: any = [];

    //----------------------- PIE
    @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

    public pieChartData!: ChartData<'pie', number[], string | string[]>;
    public pieChartOptions: ChartConfiguration['options'] = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'top'
            }
        }
    };


    public pieChartType: ChartType = 'pie';
    public pieChartPlugins = [DatalabelsPlugin];

    constructor(
        private _dataService: ApiService,
        private _chartJs: ChartjsService,
        private _tablePanel: TablePanelService,
        private _dateFormatedService: DateFormatedService
    ) { }

    async ngOnInit() {

        if (this._dataService.isLoggedIn()) {

            // recuperation de l'utilisateur
            const USER = await this._dataService.getUserData(this._dataService.getToken() + '');
            this.user = USER;

            // convert number to name type
            switch ((this.user.user_role_enedis_type.toString())) {
                case "1":
                    this.roleValue = "Consommateur";
                    this.graphPie = true;
                    this.tableConsumption = true;
                    break;
                case "2":
                    this.roleValue = "Producteur";
                    this.tableProduction = true;
                    break;
                case "3":
                    this.roleValue = "Producteur et Consommateur";
                    this.graphPie = true;
                    this.tableConsumption = true;
                    this.tableProduction = true;
                    break;
                case "4":
                    this.roleValue = "Gestionnaire PMO";
                    break;
                case "5":
                    this.roleValue = "Gestionnaire Multi PMO";
                    break;
                case "6":
                    this.roleValue = "Administrateur";
                    this.tableConsumption = true;
                    this.tableProduction = true;
                    this.graphPie = true;
                    break;
            }

            if (this.graphPie) {
                this.fn_graphPie();
            }

            // Creation du tableauCons AdminPanel en bas de page
            // Si tableConsumption = true alors on affiche le graphique
            if (this.tableConsumption) {
                this.tablePanelCons();
            }
            // Si tableProduction = true alors on affiche le graphique
            if (this.tableProduction) {
                this.tablePanelProd();
            }

        }

    }

    async fn_graphPie() {

        /**
         * @Author: John Ben
         * @Date: 2022-11-08 00:14:43
         * @Desc:Graphique en Camembert Energie (kWh) mise à disposition sur la période précédente suivant clé de répartition
         */
        let PIE_CHART_DATA: any = [];
        PIE_CHART_DATA = await this._chartJs.chartjs(
            this._dateFormatedService.lastMonthDateStart({ returnObject: true }),
            this._dateFormatedService.lastMonthDateEnd({ returnObject: true }),
            this.user.user_usage_point_id, 4, false, true, true, false, false, "month"
        );

        // Graphique Pie
        // pour la date au dessus du Graphique
        this.graphPeriodeDebut = format(this._dateFormatedService.lastMonthDateStart({ returnObject: true }), 'dd MMMM yyyy', { locale: fr });
        this.graphPeriodeFin = format(this._dateFormatedService.lastMonthDateEnd({ returnObject: true }), 'dd MMMM yyyy', { locale: fr });

        // Si pas de données ce mois-ci mettre les données du mois précédent
        // on utilisate la date de fin comme date de debut
        let moisPrecedent: string = this._dateFormatedService.lastMonthDateStart({});
        // tant que pas de données on deduit un mois
        while (PIE_CHART_DATA.data[0] == 0 && PIE_CHART_DATA.data[1] == 0) {

            PIE_CHART_DATA = await this._chartJs.chartjs(this._dateFormatedService.lastMonthDateStart({ date: moisPrecedent }), this._dateFormatedService.lastMonthDateEnd({ date: moisPrecedent, returnObject: true }), this.user.user_usage_point_id, 4, false, true, true, false, false, "month");

            // pour la date au dessus du Graphique
            this.graphPeriodeDebut = format(this._dateFormatedService.lastMonthDateStart({ date: moisPrecedent, returnObject: true }), 'dd MMMM yyyy', { locale: fr });
            this.graphPeriodeFin = format(this._dateFormatedService.lastMonthDateEnd({ date: moisPrecedent, returnObject: true }), 'dd MMMM yyyy', { locale: fr });

            if(moisPrecedent >= "2020-02-01"){
                moisPrecedent = this._dateFormatedService.lastMonthDateStart({ date: moisPrecedent });
            }   else {
                this.graphPeriodeDebut = this.graphPeriodeFin = "####-##-##";
                this.autoConsomation = "Pas de données sur cette période...";
                // this.dataSourceCons[0].col1 = "####-##-##";
            }
        }

        this.pieChartData = PIE_CHART_DATA;


        this.autoConsomation = PIE_CHART_DATA.data[0];

        const DATA = {
            labels: PIE_CHART_DATA.labels,
            datasets: [{
                data: [PIE_CHART_DATA.data[0], PIE_CHART_DATA.data[1]], // supression de parseInt
                backgroundColor: [
                    '#8fbb99', // conso
                    '#d8e7e1' // complement
                    // "rgba(203, 16, 16, 0.8)"
                ],
                hoverBackgroundColor: [
                    '#8fbb99',
                    '#d8e7e1' // complement
                    // "rgba(203, 16, 16, 0.8)"
                ],
                hoverBorderWidth: 0,
                hoverOffset: 20

            }]
        };

        //options
        // const options = {
        //     responsive: true,
        //     title: {
        //         display: true,
        //         position: "top",
        //         text: "Pie Chart",
        //         fontSize: 18,
        //         fontColor: "#111"
        //     },
        //     legend: {
        //         display: true,
        //         position: "bottom",
        //         labels: {
        //             fontColor: "#333",
        //             fontSize: 16
        //         }
        //     }
        // };

        this.pieChartData = DATA;
        // console.log('this.pieChartData :>> ', this.pieChartData);
        this.chart?.update();
    }

    // Tableau récapitulatif ----------------------
    async tablePanelCons() {

        // start and end date
        const USER_START_DATE_TO_ENEDIS = new Date(this.user.user_start_to_enedis);
        const DATE_LAST_MONTH_END = new Date(this._dateFormatedService.lastMonthDateEnd({ returnObject: true }));

        // contruit un tableauCons avec tous les mois depuis inscription
        const getDateArray = (start: any, end: any) => {
            const arr: string[] = [];
            let dt: any = new Date(start);
            while (dt <= end) {
                arr.push(format(dt, "yyyy-MM-dd"));
                dt.setMonth(dt.getMonth() + 1);
            }
            return arr;
        };

        //Tableau des Dates
        // contient la liste des dates
        const ARRAY_DATE = getDateArray(USER_START_DATE_TO_ENEDIS, DATE_LAST_MONTH_END);

        await new Promise((resolve) => {

            let count: number = 0;
            // foreach mois dans le tableauCons on appel userTablePanelCons
            ARRAY_DATE.forEach(async (element) => {
                let ligneCons: any = await this._tablePanel.userTablePanelCons(element, this.user, "month");

                // Si pas de données on ne l'affiche pas
                if (ligneCons.col2 != "0" || ligneCons.col3 != "0" || ligneCons.col5 != "0") {
                    // console.log('ligneCons :>> ', ligneCons);

                    // a chaque boucle on rempli le tableauCons  this.tableauCons
                    this.tableauCons.push(ligneCons);

                    // trier en ordre decroissant
                    this.sortTable(this.tableauCons);
                    // console.log('this.tableauCons :>> ', this.tableauCons);
                    // actualise le tableauCons pour prendre les dernières valeurs


                }

                this.dataSourceCons = this.tableauCons;

                // Total du taleau
                // for (const key in ligneCons) {

                //     if (key != "col1") {
                //         this.tableauConstotal[key] = parseFloat(this.tableauConstotal[key]) + ligneCons[key];
                //     }
                // }

                //  Si la longueur du tableua est égale au nombre d'element
                count++;
                if (count == ARRAY_DATE.length) {
                    if (this.tableauCons.length == 0) {
                        let tmp: any = {col1: "Pas de", col2: "données", col3: "sur", col4: "cette", col5: "période..."};
                        this.dataSourceCons.push(tmp);
                    }
                    resolve(1);
                };

            });
        }).then(() => {
            // Actualise le tableauCons
            try {
                this.mytablecons.renderRows();
            } catch (error) {
                console.debug(error);
            }
        });

        // if (buildGraph) {
        // }
    }

    async tablePanelProd() {

        // start and end date
        const USER_START_DATE_TO_ENEDIS = new Date(this.user.user_start_to_enedis);
        const DATE_LAST_MONTH_END = new Date(this._dateFormatedService.lastMonthDateEnd({ returnObject: true }));

        // contruit un tableauCons avec tous les mois depuis inscription
        const getDateArray = function (start: any, end: any) {
            const arr: string[] = [];
            let dt: any = new Date(start);
            while (dt <= end) {
                arr.push(format(dt, "yyyy-MM-dd"));
                dt.setMonth(dt.getMonth() + 1);
            }
            return arr;
        };
        //Tableau des Dates
        // contient la liste des dates
        const ARRAY_DATE = getDateArray(USER_START_DATE_TO_ENEDIS, DATE_LAST_MONTH_END);

        await new Promise((resolve) => {

            let count: number = 0;
            // foreach mois dans le tableauCons on appel userTablePanelCons
            ARRAY_DATE.forEach(async (element) => {
                let ligneProd = await this._tablePanel.userTablePanelProd(element, this.user, "month");

                // Si pas de données on ne l'affiche pas
                if (ligneProd.col2 != 0 || ligneProd.col2 != "0" && ligneProd.col4 != "0") {

                    // a chaque boucle on rempli le tableauCons  this.tableauCons
                    this.tableauProd.push(ligneProd);

                    // trier en ordre decroissant
                    this.sortTable(this.tableauProd);

                    // actualise le tableauCons pour prendre les dernières valeurs
                }

                this.dataSourceProd = this.tableauProd;

                count++;
                if (count == ARRAY_DATE.length) {
                    if (this.tableauProd.length == 0) {
                        let tmp: any = {col1: "Pas de", col2: "données", col3: "sur", col4: "cette", col5: "période..."};
                        this.dataSourceProd.push(tmp);
                    }
                    resolve(1);
                };
            });
        }).then(() => {
            try {
                this.mytablecons.renderRows();
            } catch (error) {
                console.debug(error);
            }
        });

    }

    sortTable(table: any) {
        table.sort((a: any, b: any) => {
            let fa = a.col0, fb = b.col0;

            if (fa < fb) {
                return 1;
            }
            if (fa > fb) {
                return -1;
            }
            return 0;
        });
    }
}