import { Injectable } from '@angular/core';
// formatage de la date
import { format } from 'date-fns';
// import lastDayOfMonth from 'date-fns/lastDayOfMonth';
// import addYears from 'date-fns/addYears'
import { lastDayOfMonth, addYears } from 'date-fns';
import { dateFormatedService } from 'src/app/interfaces/date-formated-service';



@Injectable({
    providedIn: 'root'
})

export class DateFormatedService {

    constructor() { }

    /**
     * @Author: John Ben
     * @Date: 2022-11-03 07:46:13
     * @Desc: retourne la date du jour
     */
    toDay() {
        const maintenant: Date = new Date(Date.now());
        return maintenant;
    }

    /**
     * @Author: John Ben
     * @Date: 2022-11-07 09:20:41
     * @Desc:
     */
    month(param: Date = this.toDay()) {
        let date: Date = new Date(this.toDay());
        let month = date.getMonth();
        return month;
    }

    /**
     * @Author: John Ben
     * @Date: 2022-11-07 09:20:37
     * @Desc:
     */
    year() {
        let date = new Date(this.toDay());
        let year = date.getFullYear();
        return year;
    }

    /**
     * @Author: John Ben
     * @Date: 2022-11-03 07:46:13
     * @Desc: retourne la date du jour au format yyyy-MM-dd
     * @param date any
     * @returns string "yyyy-MM-dd"
     */
    dateDuJourFormated(date: any = "") {
        // si date est vide on prends la date du jour
        return format(this.toDay(), "yyyy-MM-dd");
    }

    /**
     * @Author: John Ben
     * @Date: 2022-11-16 09:04:51
     * @Desc:
     */
    dateFormated(date: any) {
        // si typeof date est string on le converti en date
        if (typeof (date) == "string") {
            date = new Date(date);
        }
        return format(date, "yyyy-MM-dd");
    }

    /**
     * @Author : John Ben
     * @Date : 2022-11-03 07:40:55
     * @Desc : Construit la date de depart en calendaire ou anniversaire
     * @param typeBirthday boolean : true = anniversaire, false = calendaire
     * @returns Date
     */
    lastMonthDateStart({ date = null, typeBirthday = true, gap = false, returnObject = false }: dateFormatedService): any {
        let dateStart: Date;
        let dateParm: Date = new Date(date);
        let dateTmp: Date;

        // si date est null on prends la date du jour
        if (!date) {
            dateTmp = new Date(format(this.toDay(), "yyyy-MM-26"));
        } else {
            dateTmp = new Date(format((dateParm), "yyyy-MM-26"));
        }

        // Si gap est true on décale la date de 2 mois sinom 1 mois
        if (gap) {
            dateStart = new Date(dateTmp.setMonth(dateTmp.getMonth() - 2));
        } else {
            dateStart = new Date(dateTmp.setMonth(dateTmp.getMonth() - 1));
        }

        // si typeBirthday false on prends 1° jour du mois
        if (!typeBirthday) {
            dateStart = new Date(format(dateStart, "yyyy-MM-01"));
        }

        // si returnObject est true on retourne l'objet date sinon string "yyyy-MM-dd"
        if (returnObject) {
            let result: Date = new Date(dateStart);
            return result;
        } else {
            return this.dateFormated(dateStart);
        }
    }

    /**
     * @Author: John Ben
     * @Date: 2022-11-03 07:45:47
     * @Desc: Construit la date de fin en calendaire ou anniversaire
     * @param date Date : date de debut
     * @param type boolean : true = anniversaire, false = calendaire
     * @param returnObject boolean : true = format yyyy-MM-dd, false = format Date
     */
    lastMonthDateEnd({ date = null, typeBirthday = true, gap = false, returnObject = false }: dateFormatedService): any {
        let dateEnd: Date;
        let dateParam: Date = new Date(date);
        let dateTmp: Date;

        // si date est null on prends la date du jour
        if (!date) {
            dateTmp = new Date(format(this.toDay(), "yyyy-MM-25"));
        } else {
            dateTmp = new Date(format(dateParam, "yyyy-MM-25"));
        }

        // Si gap est true on décale la date de 2 mois sinom 1 mois
        if (gap) {
            dateEnd = new Date(dateTmp.setMonth(dateTmp.getMonth() - 1));
        } else {
            dateEnd = new Date(dateTmp.setMonth(dateTmp.getMonth()));
        }

        // si typeBirthday false on prends le dernier jour du mois
        if (!typeBirthday) {
            dateEnd = lastDayOfMonth(dateEnd);
        }

        // si returnObject est true on retourne l'objet date sinon string "yyyy-MM-dd"
        if (returnObject) {
            let result: Date = new Date(dateEnd);
            return result;
        } else {
            return this.dateFormated(dateEnd);
        }

    }

    /**
     * @Author: John Ben
     * @Date: 2022-11-03 07:45:15
     * @Desc: retourne la date du début de l'année
     */
    yearStart() {
        let date: Date = new Date(format(this.toDay(), "yyyy-01-01"));
        return date;
    }

    /**
     * @Author: John Ben
     * @Date: 2022-11-17 11:08:53
     * @Desc:
     */
    yearNextTwelveMonth(dateParam: Date = this.toDay()) {

        let date: number | Date;

        //  si dateParam est une string on la converti en date
        if (typeof (dateParam) == "string") {
            date = new Date(dateParam);
        } else {
            date = dateParam;
        }

        date = addYears(new Date(date), 1);

        return date;
    }
}