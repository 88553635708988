import { Injectable } from '@angular/core';

import { User_model } from 'src/app/interfaces/users';
import { ApiService } from '../api/api.service';

import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

@Injectable({
    providedIn: 'root'
})

export class ChartjsService {
    user!: User_model;

    //initialisation des tableaux
    data1: any = [];
    data2: any = [];
    data3: any = [];
    data4: any = [];
    data5: any = [];

    // cree un object avec les labels et les données
    label = {
        cons: "Consommation (kWh)",
        prod: "Production (kWh)",
        surplus: "Surplus (kWh)",
        autocons: "Autoconsommation (kWh)",
        complement: "Complément (kWh)",
    };


    // Type de Graphique
    graphType: string = "bar";
    //------------------------------------ ChartJS
    // dayData: any = [];
    barChartLabelsFromSql: any = [];
    //ChartData: any = [];
    public barChartOptions = {
        responsive: true,
        scales: {
            x: {
                stacked: true
            },
            y: {
                stacked: true
            }
        },
        plugins: {
            legend: {
                display: true,
            },
            datalabels: {
                anchor: 'end',
                align: 'end',
                display: false
            }
        }
    };

    //public barChartType = 'bar';
    //public barChartLegend = true;
    public barChartLabels: any;
    public barChartData: any = [];
    //------------------------------------ ChartJS

    constructor(private _dataService: ApiService) { }

    /**
     *@param string   date_start               Date Start
     *@param string   date_end                 Date end
     *@param string   user_usage_point_id      Usage point id
     *@param number   route                    Load curve ou active energy
     *@param boolean  cons                     typeEnergy true for activate
     *@param boolean  autocons                 typeEnergy true for activate
     *@param boolean  complement               typeEnergy true for activate
     *@param boolean  prod                     typeEnergy true for activate
     *@param boolean  surplus                  typeEnergy true for activate
     *@param string   view                     day,month,year
     *@param string   graphType                bar, line
     *
     * @return array
     * [0]cons ,
     * [1]autocons ,
     * [2]complement ,
     * [3]prod ,
     * [4]surplus
     *
     * Une fois tous les paramètre renseigné, ce service intérroge la route SQL et retourne un tableau contenant plusieur objects sous la forme:
     * RESULTAT dashboard
     *    barChartLabels:
     *    0: Array [
     *                0: "2020"
     *             ​​   1: "2021"
     *              ]
     *
     *    barChartData:
     *    1: Array(3) [ {…}, {…}, {…} ]
     *                0: Object {
     *                           data: Array [ "541.70", "1604.70" ]
     *                           label: "cons"
     *                           }
     *                1: Object {
     *                           data: Array [ "22.44", "348.29" ]
     *                           label: "autocons"
     *                           }
     *                2: Object {
     *                           data: Array [ "519.25", "1256.34" ]
     *                           label: "complement"
     *                           }
     *
     * X pour le user Id permets d'avoir un calcul globale
     */
    async chartjs(date_start: string, date_end: string, user_usage_point_id: string, route: number, cons: boolean, autocons: boolean, complement: boolean, prod: boolean, surplus: boolean, view: string, graphType: string = "") {

        this.data1 = [];
        this.data2 = [];
        this.data3 = [];
        this.data4 = [];
        this.data5 = [];
        this.barChartLabelsFromSql = [];
        this.barChartLabels = [];

        let labelType: string;

        // Calcule du taux de consommation
        // Quantité totale d’énergie autoproduite*  /quantité totale d’énergie soutirée
        let graphLine1: any[] = [];
        let tauxAutoConso: any[] = [];

        //Calcul du taux de production
        // électricité autoconsommée / électricité autoproduite) x 100
        let graphLine2: any[] = [];
        let tauxAutoProd: any[] = [];

        //charge la courbe de production
        switch (route) {

            // Route 1 => Definitive active energy
            case 1:
                if (cons) {
                    let typeEnergy = "cons";
                    labelType = this.label.cons;
                    this.data1 = await this.getActiveEnergyData(date_start, date_end, user_usage_point_id, typeEnergy, labelType, view);
                }

                if (autocons) {
                    let typeEnergy = "autocons";
                    labelType = this.label.autocons;
                    this.data2 = await this.getActiveEnergyData(date_start, date_end, user_usage_point_id, typeEnergy, labelType, view);
                }

                if (complement) {
                    let typeEnergy = "complement";
                    labelType = this.label.complement;
                    this.data3 = await this.getActiveEnergyData(date_start, date_end, user_usage_point_id, typeEnergy, labelType, view);
                }

                if (prod) {
                    let typeEnergy = "prod";
                    labelType = this.label.prod;
                    this.data4 = await this.getActiveEnergyData(date_start, date_end, user_usage_point_id, typeEnergy, labelType, view);
                }

                if (surplus) {
                    let typeEnergy = "surplus";
                    labelType = this.label.surplus;
                    this.data5 = await this.getActiveEnergyData(date_start, date_end, user_usage_point_id, typeEnergy, labelType, view);
                }
                break;

            // Route 2 => load Curve
            case 2:
                if (cons) {
                    let typeEnergy = "cons";
                    labelType = this.label.cons;
                    this.data1 = await this.getCurveData(date_start, date_end, user_usage_point_id, typeEnergy, labelType, view);
                }

                if (autocons) {
                    let typeEnergy = "autocons";
                    labelType = this.label.autocons;
                    this.data2 = await this.getCurveData(date_start, date_end, user_usage_point_id, typeEnergy, labelType, view);
                }

                if (complement) {
                    let typeEnergy = "complement";
                    labelType = this.label.complement;
                    this.data3 = await this.getCurveData(date_start, date_end, user_usage_point_id, typeEnergy, labelType, view);
                }

                if (prod) {
                    let typeEnergy = "prod";
                    labelType = this.label.prod;
                    this.data4 = await this.getCurveData(date_start, date_end, user_usage_point_id, typeEnergy, labelType, view);
                }

                if (surplus) {
                    let typeEnergy = "surplus";
                    labelType = this.label.surplus;
                    this.data5 = await this.getCurveData(date_start, date_end, user_usage_point_id, typeEnergy, labelType, view);
                }
                break;

            // Route 3 => load Curve Admin
            case 3:
                if (cons) {
                    let typeEnergy = "cons";
                    labelType = this.label.cons;
                    this.data1 = await this.getAdminCurveData(date_start, date_end, user_usage_point_id, typeEnergy, labelType, view);
                }

                if (autocons) {
                    let typeEnergy = "autocons";
                    labelType = this.label.autocons;
                    this.data2 = await this.getAdminCurveData(date_start, date_end, user_usage_point_id, typeEnergy, labelType, view);
                }

                if (complement) {
                    let typeEnergy = "complement";
                    labelType = this.label.complement;
                    this.data3 = await this.getAdminCurveData(date_start, date_end, user_usage_point_id, typeEnergy, labelType, view);
                }

                if (prod) {
                    let typeEnergy = "prod";
                    labelType = this.label.prod;
                    this.data4 = await this.getAdminCurveData(date_start, date_end, user_usage_point_id, typeEnergy, labelType, view);
                }

                if (surplus) {
                    let typeEnergy = "surplus";
                    labelType = this.label.surplus;
                    this.data5 = await this.getAdminCurveData(date_start, date_end, user_usage_point_id, typeEnergy, labelType, view);
                }

                if (route === 3 && this.data4.length > 0 && this.data1.length > 0) {
                    for (let i = 0; i < Math.max(this.data1.length, this.data4[0].data.length); i++) {
                        graphLine1[i] = ((this.data4[0].data[i]) / this.data1[0].data[i]).toFixed(0);
                    }
                    tauxAutoConso = [{ type: "line", data: graphLine1, label: "Taux AutoConso" }];
                }

                if (route === 3 && this.data4.length > 0 && this.data2.length > 0) {
                    for (let i = 0; i < Math.max(this.data2.length, this.data4[0].data.length); i++) {
                        graphLine2[i] = (((this.data2[0].data[i]) * 100) / this.data4[0].data[i]).toFixed(0);
                    }
                    tauxAutoProd = [{ type: "line", data: graphLine2, label: "Taux AutoProd" }];
                }
                break;

            // Route 4 => load Curve Circulaire graph
            case 4:
                // if (cons) {
                //     // console.log("Date start : " + date_start, date_end);
                //     let typeEnergy = "cons";
                //     labelType = "Consommation(kWh)";
                //     this.data1 = await this.getAdminCurveData(date_start, date_end, user_usage_point_id, typeEnergy, labelType, view);
                //     this.data1 = this.data1[0].data.reduce((p: string, c: string) => parseFloat(p) + parseFloat(c), 0);
                //     // console.log("Data1", this.data1);
                //     this.barChartLabels.push(labelType);
                // }

                if (autocons) {
                    let typeEnergy = "autocons";
                    labelType = this.label.autocons;
                    let data2 = await this.getAdminCurveData(date_start, date_end, user_usage_point_id, typeEnergy, labelType, view);
                    // Laisser 2 chiffres après la virgule pour avoir la même valeur dans le tableau!
                    this.data2 = (data2[0].data.reduce((p: string, c: string) => parseFloat(p) + parseFloat(c), 0)).toFixed(2);
                    this.barChartLabels.push(labelType);
                }

                if (complement) {
                    let typeEnergy = "complement";
                    labelType = this.label.complement;
                    let data3 = await this.getAdminCurveData(date_start, date_end, user_usage_point_id, typeEnergy, labelType, view);
                    // Laisser 2 chiffres après la virgule pour avoir la même valeur dans le tableau!
                    this.data3 = (data3[0].data.reduce((p: string, c: string) => parseFloat(p) + parseFloat(c), 0)).toFixed(2);
                    this.barChartLabels.push(labelType);
                }


                // console.log('this.data2 Auto-conso:>> ', this.data2);
                // console.log('this.data3 complement:>> ', this.data3);

                // let calcul = (this.data2 * 100) / this.data3;
                const RESULTAT = {
                    labels: this.barChartLabels,
                    data: [
                        // parseInt(this.data1), // conso total du point de livraison
                        parseInt(this.data2, 10),  // autoconsommation
                        parseInt(this.data3, 10), // complement total du point de livraison
                    ]
                };
                return RESULTAT;
                break;

            // Route 2 => load Curve Production graph
            case 5:
                if (cons) {
                    let typeEnergy = "cons";
                    labelType = this.label.cons;
                    this.data1 = await this.getCurveData(date_start, date_end, user_usage_point_id, typeEnergy, labelType, view);
                }

                if (autocons) {
                    let typeEnergy = "autocons";
                    labelType = this.label.autocons;
                    this.data2 = await this.getCurveData(date_start, date_end, "x", typeEnergy, labelType, view);
                }

                if (complement) {
                    let typeEnergy = "complement";
                    labelType = this.label.complement;
                    this.data3 = await this.getCurveData(date_start, date_end, user_usage_point_id, typeEnergy, labelType, view);
                }

                if (prod) {
                    let typeEnergy = "prod";
                    labelType = this.label.prod;
                    this.data4 = await this.getCurveData(date_start, date_end, user_usage_point_id, typeEnergy, labelType, view);
                }

                if (surplus) {
                    let typeEnergy = "surplus";
                    labelType = this.label.surplus;
                    this.data5 = await this.getCurveData(date_start, date_end, user_usage_point_id, typeEnergy, labelType, view);
                }
                break;
            default:
                return console.error("Pas de données dans le service ChartsJs");
                break;
        }


        // console.log('this.data2 :>> ', this.data2);
        // console.log('this.data3 :>> ', this.data3);
        // console.log('this.data4 :>> ', this.data4);
        // console.log('this.data5 :>> ', this.data5);
        // console.log('tauxAutoConso :>> ', tauxAutoConso);
        // console.log('tauxAutoProd :>> ', tauxAutoProd);

        // cree l'objet si les variables sont remplis
        const DATA_TOTAL = [
            //...cons ? this.data1 : "",
            ...autocons ? this.data2 : "",
            ...complement ? this.data3 : "",
            ...prod ? this.data4 : "",
            ...surplus ? this.data5 : "",
            ...tauxAutoConso ? tauxAutoConso : "",
            ...tauxAutoProd ? tauxAutoProd : ""
        ];

        // chargement des réglages du Tableau
        /* Model de barChartData
        [
            {"label": "cons","data": ["6198.21"]},
            {"label": "complement","data": ["67629.81"]}
        ]
        */
        this.barChartData = DATA_TOTAL;

        if (this.barChartData[0]) {
            switch (this.barChartData[0].label) {
                case this.label.prod:
                    this.barChartData[0].backgroundColor = ['#8fbb99'];
                    this.barChartData[0].hoverBackgroundColor = ['#d8e7e1'];
                    break;
                case this.label.autocons:
                    this.barChartData[0].backgroundColor = ['#8fbb99'];
                    this.barChartData[0].hoverBackgroundColor = ['rgb(99, 128, 106)'];
                    break;
            }
        }
        if (this.barChartData[1]) {
            switch (this.barChartData[1].label) {
                case this.label.surplus:
                    this.barChartData[1].backgroundColor = ['rgba(203, 16, 16, 0.8)'];
                    this.barChartData[1].hoverBackgroundColor = ['rgba(203, 16, 16, 0.3)'];
                    break;
                case this.label.complement:
                    this.barChartData[1].backgroundColor = ['#d8e7e1'];
                    this.barChartData[1].hoverBackgroundColor = ['#78807D'];
                    break;

            }
        }
        if (this.barChartData[2]) {
            switch (this.barChartData[2].label) {
                case this.label.surplus:
                    this.barChartData[2].backgroundColor = ['rgba(203, 16, 16, 0.8)'];
                    this.barChartData[2].hoverBackgroundColor = ['rgba(203, 16, 16, 0.3)'];
                    break;
                case this.label.complement:
                    this.barChartData[2].backgroundColor = ['#d8e7e1'];
                    this.barChartData[2].hoverBackgroundColor = ['rgba(216, 231, 225,0.8)'];
                    break;

            }
        }

        // Ajoute des couleurs pour chaque elements
        // this.barChartData[0] ? this.barChartData[0].backgroundColor = ['#00FF53'] : ""; // cons
        // this.barChartData[0] ? this.barChartData[0].backgroundColor = ['#8fbb99'] : ""; // Prod
        // this.barChartData[1] ? this.barChartData[1].backgroundColor = ['rgba(203, 16, 16, 0.8)'] : ""; // Surplus
        // this.barChartData[2] ? this.barChartData[2].backgroundColor = ['rgba(203, 16, 16, 0.8)'] : ""; // surplus
        // this.barChartData[2] ? this.barChartData[2].backgroundColor = ['#c0d832'] : "";
        // this.barChartData[3] ? this.barChartData[3].backgroundColor = ['#5a8585'] : "";

        // this.barChartData[0] ? this.barChartData[0].hoverBackgroundColor = ['#8fbb99'] : ""; // autoconso
        // this.barChartData[1] ? this.barChartData[1].hoverBackgroundColor = ['#d8e7e1'] : ""; // complement
        // this.barChartData[2] ? this.barChartData[2].hoverBackgroundColor = ['rgba(203, 16, 16, 0.8)'] : ""; // surplus
        // this.barChartData[3] ? this.barChartData[3].hoverBackgroundColor = ['rgba(203, 16, 16, 0.8)'] : "";
        // this.barChartData[3] ? this.barChartData[3].hoverBackgroundColor = ['#5a8585'] : "";

        this.barChartLabels = ([...new Set(this.barChartLabelsFromSql)]);
        this.barChartLabels = this.barChartLabels.filter((e: null) => e != null);

        // console.log('this.barChartLabels :>> ', route, this.barChartLabels);
        const RESULTAT = [this.barChartLabels, DATA_TOTAL];

        return RESULTAT;

    }

    async getActiveEnergyData(date_start: string, date_end: string, user_usage_point_id: string, typeEnergy: string, labelType: string, view: string) {

        let dayData;
        let result: boolean = true;

        const LOAD_CURVES_DATA = await this._dataService.getActiveEnergyData(date_start, date_end, user_usage_point_id, typeEnergy, view);

        await LOAD_CURVES_DATA ? dayData = await LOAD_CURVES_DATA : result = false;

        if (result == true) {

            try {

                // Pour chaque element de dayData.data arrondi à la virgule
                dayData.data = dayData.data.map((e: string) => parseFloat(e).toFixed(0));

                Object.entries(dayData).forEach((element: any) => {
                    // si element[0] est label on remplace par le labelType: cons/prod/complement....
                    if (element[0] === "label") {
                        element["label"] = labelType;
                    }

                    //  si element[0] est barChartLabels qui est l'année on la recupère
                    if (element[0] === "barChartLabels") {
                        // Ajoute chaque année ( date en bas de colonne ) dans un  tableau barChartLabelsFromSql
                        this.barChartLabelsFromSql.push(element[1]);

                        //  on le supprime de l'object
                        delete element["barChartLabels"];

                    }
                });

            } catch (error) {
                // console.error(error);
                this.barChartLabelsFromSql = ["Pas de", "Données"];
                dayData = [{ "data": ["0", "0"], "label": labelType }];
            }

        }

        // reglage des labels Text en bas
        // reglage des données bar vertical
        // Si il y a plusieur Data on reduit

        let A: any;
        if (dayData["data"]) {
            A = dayData["data"].reduce((p: any[], c: any) => {
                p.push(...c);
                return p;
            }, []);
        } else {
            A = dayData["data"];
        }

        return [{
            type: this.graphType, data: A, label: labelType
        }];
    }

    /**
     *
     * Récupère les courbes de charges
     *
     * @params date_start: string Date de debut
     * @params date_end: string Date de fin
     * @params user_usage_point_id: string identifiant utilisateur
     * @params typeEnergy: string cons,surplus,autocons
     * @params labelType: string
     * @params view: string
     *
     * @return
     *          [
     *            {
     *                "label": "surplus",
     *                "barChartLabels": [
     *                "2000-00-10",
     *                "2000-00-11",
     *                "2000-00-10"
     *                ],
     *                "data": [
     *                "885.929898",
     *                "635.024100",
     *                "135.183700"
     *                ]
     *            }
     *            ]
     */

    async getCurveData(date_start: string, date_end: string, user_usage_point_id: string, typeEnergy: string, labelType: string, view: string, graphType: string = 'bar') {

        let dayData;
        let result = true;

        // interoge la base de données
        const LOAD_CURVES_DATA = this._dataService.getCurveData(date_start, date_end, user_usage_point_id, typeEnergy, view);
        await LOAD_CURVES_DATA ? dayData = await LOAD_CURVES_DATA : result = false;

        if (result == true) {

            try {

                dayData.forEach((element: any) => {
                    // Ajoute chaque barcahrtlabel dans un  tableau barChartLabelsFromSql et le supprime de l'object
                    // element[ "barChartLabels" ] => date du jour
                    // on remplace par le labelType
                    element["label"] = labelType;
                    //  check si non null
                    if (element["barChartLabels"]) {
                        element["barChartLabels"].forEach((element: string) => {
                            // pour le jour on supprime yyyy-mm- et on garde que le jour
                            this.barChartLabelsFromSql.push(element.slice(8));
                        });
                    } else {
                        this.barChartLabelsFromSql = ["Pas de", "Données"];
                        dayData = [{ "data": ["0", "0"], "label": labelType }];
                    }
                });

            } catch (error) {

                this.barChartLabelsFromSql = ["Pas de", "Données"];
                dayData = [{ "data": ["0", "0"], "label": labelType }];

            }

        }

        // reglage des labels Text en bas
        // reglage des données bar vertical
        const A = dayData.reduce((p: any[], c: any) => {

            // fixe a deux chiffre apres la virgule
            c.data.forEach((element: any, index: number) => {
                // tofixe a deux chiffre apres la virgule pour les graphiques en bar
                c.data[index] = parseFloat(element).toFixed(2);
            });
            p.push(...c.data);
            return p;
        }, []);

        // Si c'est SURPLUS on converti tout l'object en negatif
        let negatif: any[] = [];
        if (typeEnergy === "surplus") {
            A.forEach((element: any) => {
                // element = (-Math.abs(parseFloat(element)));
                element = (Math.abs(parseFloat(element)));
                //element = element.toString();
                negatif.push(element);
            });

            return [{ type: graphType, data: negatif, label: dayData[0].label }];
        } else {
            return [{
                type: graphType, data: A, label: dayData[0].label

            }];
        }

    }

    /**
     *
     * Récupère les courbes de charges pour les admin
     *
     * @params date_start: string Date de debut
     * @params date_end: string Date de fin
     * @params user_usage_point_id: string identifiant utilisateur
     * @params typeEnergy: string cons,surplus,autocons
     * @params labelType: string TypeEnergy bien ecrit Consommation, Production ....
     * @params view: string
     *
     * @return
     *          [
     *            {
     *                "label": "surplus",
     *                "barChartLabels": [
     *                "2000-00-10",
     *                "2000-00-11",
     *                "2000-00-10"
     *                ],
     *                "data": [
     *                "885.929898",
     *                "635.024100",
     *                "135.183700"
     *                ]
     *            }
     *            ]
     */
    async getAdminCurveData(date_start: string, date_end: string, user_usage_point_id: string, typeEnergy: string, labelType: string, view: string) {

        let dayData;
        let result = true;

        // interoge la base de données
        const LOAD_CURVES_DATA = this._dataService.getCurveData(date_start, date_end, user_usage_point_id, typeEnergy, view);
        await LOAD_CURVES_DATA ? dayData = await LOAD_CURVES_DATA : result = false;

        if (result == true) {

            try {

                dayData.forEach((element: any) => {
                    // Ajoute chaque barcahrtlabel dans un  tableau barChartLabelsFromSql et le supprime de l'object
                    // element[ "barChartLabels" ] => date du jour

                    //  check si non null
                    if (element["barChartLabels"]) {

                        element["barChartLabels"].forEach((element: string) => {
                            // pour le jour on supprime yyyy-mm- et on garde que le jour
                            this.barChartLabelsFromSql.push(element.slice(8));
                        });
                    } else {
                        this.barChartLabelsFromSql = ["Pas de", "Données"];
                        dayData = [{ "data": ["0", "0"], "label": labelType }];
                    }
                });

            } catch (error) {

                this.barChartLabelsFromSql = ["Pas de", "Données"];
                dayData = [{ "data": ["0", "0"], "label": labelType }];

            }
        }

        // reglage des labels Text en bas
        // reglage des données bar vertical
        const A = dayData.reduce((p: any[], c: any) => {
            p.push(...c.data);
            return p;
        }, []);

        // Si c'est SURPLUS on converti tout l'object en negatif
        let negatif: any[] = [];
        if (typeEnergy === "surplus") {
            A.forEach((element: any) => {
                // Passe Surplus en negatif
                element = (-Math.abs(parseFloat(element)));
                console.log('element :>> ', element);
                //element = element.toString();
                negatif.push(element);
            });

            return [{ type: this.graphType, data: negatif, label: dayData[0].label }];
        } else {
            return [{
                type: this.graphType, data: A, label: dayData[0].label

            }];
        }
    }
}
