import { Component, OnInit } from '@angular/core';
// form
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
// Router
import { Router } from '@angular/router';
// Services
import { ApiService } from 'src/app/services/api/api.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    data = [];

    baseUrl: string = environment.baseUrl;
    angForm: UntypedFormGroup;
    constructor(
        // private _fb: FormBuilder,
        private _dataService: ApiService,
        private _router: Router
    ) {
        const res = {};

        this.angForm = new UntypedFormGroup({
            password: new UntypedFormControl('', [Validators.required, Validators.minLength(1)]),
            email: new UntypedFormControl('', [Validators.required, Validators.email, Validators.minLength(1)])
        });
        this.angForm.patchValue(res);
    }

    ngOnInit() { }

    async postdata(angForm: { value: { email: string; password: string; }; }) {

        const RESPONSE = await this._dataService.userlogin(angForm.value.email, angForm.value.password);
        if (RESPONSE?.user_email) {
            const REDIRECT = this._dataService.redirectUrl ? this._dataService.redirectUrl : '/accueil';
            this._router.navigate([REDIRECT]);
        } else {
            alert("Nom d'utilisateur ou mot de passe incorrect.");
        }
    }
    get email() { return this.angForm.get('email'); }
    get password() { return this.angForm.get('password'); }
}